import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, TextInput } from 'react-native';
import { color } from '../../../helper/theme-helper';
import PropTypes from 'prop-types';
// import { AppButton } from '../../layouts';

export default class NewQuestion extends React.Component {
  static propTypes = {
    setHeader: PropTypes.func,
    onPost: PropTypes.func,
    // showModal: PropTypes.bool,
    // onBack: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      question: '',
    };
  }
  componentDidMount() {
    this.props.setHeader({
      headerShown: true,
      headerTitle: null,
      headerRight: () => (
        <TouchableOpacity onPress={this.onPost}>
          <Text style={styles.postText}>Post</Text>
        </TouchableOpacity>
      ),
    });
  }
  onPost = () => {
    if (this.state.question.length > 20) {
      this.props.onPost(this.state.question);
    } else {
      alert('Reply is too short. Must be at least 20 characters.');
    }
  };
  render() {
    const { container, textInput } = styles; //modal, modalContent, thankYouText, detailText
    const { question } = this.state;
    return (
      <View style={container}>
        <TextInput
          value={question}
          onChangeText={(question) => this.setState({ question })}
          multiline={true}
          autoFocus={true}
          placeholder={'Ask something...'}
          style={textInput}
          placeholderTextColor={color.gray}
        />
      </View>
    );
  }
}
// {this.props.showModal && (
//   <View style={modal}>
//     <View style={modalContent}>
//       <TouchableOpacity style={{ paddingHorizontal: 15 }}>
//         <Text style={thankYouText}>Thank You!</Text>
//         <Text style={detailText}>
//           Your question has been recieved. We are reviewing it. Check back soon for replies!
//         </Text>
//         <AppButton title="Got it!" onPress={() => this.props.onBack()} />
//       </TouchableOpacity>
//     </View>
//   </View>
// )}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: color.white,
  },
  postText: {
    fontSize: 14,
    lineHeight: 17,
    color: color.midBlue,
    fontWeight: 'bold',
  },
  thankYouText: {
    fontSize: 18,
    lineHeight: 22,
    fontWeight: 'bold',
    marginBottom: 10,
    color: color.darkGray,
  },
  detailText: {
    marginTop: 10,
    fontSize: 14,
    marginBottom: 20,
  },
  modal: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  modalContent: {
    backgroundColor: '#fefefe',
    marginVertical: '15%',
    padding: 20,
    borderWidth: 1,
    borderColor: color.backgroundGray,
    borderRadius: 5,
    width: '80%',
  },
  textInput: {
    textAlignVertical: 'top',
    paddingHorizontal: 17,
    fontSize: 14,
    lineHeight: 20,
    color: color.darkGray,
    borderWidth: 2,
    margin: 17,
    minHeight: 120,
    borderRadius: 3,
    borderColor: color.lightGray,
    paddingVertical: 19,
  },
});
