import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '../../authentication';
import Job from '../../components/pages/job';
import { observer, inject } from 'mobx-react';

class JobPage extends React.Component {
  static propTypes = {
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
    }).isRequired,
    JobStore: PropTypes.shape({
      getPersonalJobs: PropTypes.func.isRequired,
      putViewedJobs: PropTypes.func.isRequired,
    }),
    auth: PropTypes.shape({}).isRequired,
  };

  constructor(props) {
    super(props);
    const { auth, JobStore } = this.props;
    JobStore.getPersonalJobs(auth);
  }

  //TODO, when we have backend that can search
  onFindJobsClick = () => {};
  onJobClick = (id, title, company, description, lastViewedAt, externalUrl, application) => {
    const { auth, JobStore } = this.props;
    if (!lastViewedAt) {
      JobStore.putViewedJobs(auth, id);
    }
    this.props.navigation.navigate('SubmitApplication', {
      id,
      title,
      company,
      description,
      externalUrl,
      application,
    });
  };

  render() {
    return <Job onFindJobsClick={this.onFindJobsClick} onJobClick={this.onJobClick} />;
  }
}

export { JobPage };
export default inject('JobStore')(observer(withAuth(JobPage)));
