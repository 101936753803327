/*eslint-env node*/
/*
export default {
  // shared aws account settings:
  Auth: {
    region: process.env.REACT_APP_AWS_PROJECT_REGION || 'us-east-2',
    identityPoolId:
      process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID ||
      'us-east-2:e6ec692c-78ee-4dae-8f16-cefe9af47f96',
    identityPoolRegion: process.env.REACT_APP_AWS_COGNITO_REGION || 'us-east-2',
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID || 'us-east-2_xlMfz3PZT',
    userPoolWebClientId:
      process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID || '4chdfe6ivp3efmiqt7hv3hmel',
    mandatorySignIn: false,
  },
};
*/

export default {
  // experimental aws account settings
  Auth: {
    region: process.env.REACT_APP_AWS_PROJECT_REGION || 'us-east-2',
    identityPoolId:
      process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID ||
      'us-east-2:be3c03eb-f86e-4d5c-9554-bebab2f59b54',
    identityPoolRegion: process.env.REACT_APP_AWS_COGNITO_REGION || 'us-east-2',
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID || 'us-east-2_ztznLRZAV',
    userPoolWebClientId:
      process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID || '4b1vl059iq4ko3euq10ne378si',
    mandatorySignIn: false,
  },
};
