import { observable, runInAction, decorate } from 'mobx';
import QuestionsService from '../services/questions-service';

class QuestionsStore {
  constructor() {
    this.questionService = new QuestionsService();
  }
  questionData = {
    model: [],
  };
  questionWithResponses = {
    model: [],
  };

  getAllQuestions = async (auth) => {
    const self = this;
    try {
      const data = await this.questionService.getAllQuestions(auth);
      runInAction(() => {
        this.questionData = {
          model: data,
        };
      });
      // get updated question every 30 seconds
      setTimeout(function () {
        self.getAllQuestions(auth);
      }, 15 * 1000);
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
  };

  getQuestion = async (auth, questionId) => {
    try {
      const data = await this.questionService.getSingleQuestion(auth, questionId);
      runInAction(() => {
        this.questionWithResponses = {
          model: data,
        };
      });
    } catch (error) {
      console.error('getQuestion error', error);
      runInAction(() => {
        this.status = 'error';
      });
    }
  };

  addResponse = async (auth, questionId, body) => {
    try {
      await this.questionService.postResponse(auth, questionId, body);
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
    this.getQuestion(auth, questionId);
  };

  addQuestion = async (auth, body) => {
    try {
      await this.questionService.postQuestion(auth, body);
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
    this.getAllQuestions(auth);
  };
}

decorate(QuestionsStore, {
  questionData: observable,
  questionWithResponses: observable,
});

export default new QuestionsStore();
