import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '../../authentication';
import ConfirmEmail from '../../components/pages/confirm-email';

class ConfirmEmailPage extends React.Component {
  static propTypes = {
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
    }).isRequired,
    auth: PropTypes.shape({
      confirmSignUp: PropTypes.func.isRequired,
      resendSignUp: PropTypes.func.isRequired,
      getSigningUpUser: PropTypes.func,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      emailResent: false,
    };
  }

  confirmSignUp = async (email, code) => {
    this.setState({ loading: true });
    const { auth, navigation } = this.props;
    const { error } = await auth.confirmSignUp(email, code);
    if (error) {
      this.setState({ loading: false, error });
    } else {
      this.setState({ loading: false, error: null });
      navigation.navigate('LogInPage');
    }
  };

  resendEmail = async (email) => {
    this.setState({ loading: true });
    const { auth } = this.props;
    const { error } = await auth.resendSignUp(email);
    if (error) {
      this.setState({ loading: false, error });
    } else {
      this.setState({ loading: false, error: null, emailResent: true });
    }
  };

  render() {
    const { loading, error, emailResent } = this.state;
    const { auth } = this.props;
    const signingUpUser = auth.getSigningUpUser();
    const email = signingUpUser ? signingUpUser.username : null;
    return (
      <ConfirmEmail
        loading={loading}
        email={email}
        error={error}
        emailResent={emailResent}
        confirmSignUp={this.confirmSignUp}
        resendEmail={this.resendEmail}
      />
    );
  }
}

export { ConfirmEmailPage };
export default withAuth(ConfirmEmailPage);
