class UserService {
  get = async (auth) => {
    const response = await auth.awsFetch('/api/v1/users/me', 'GET');
    return response.json();
  };
  post = async (auth, body) => {
    const response = await auth.awsFetch('/api/v1/users/', 'POST', body);
    return response.json();
  };
  put = async (auth, body) => {
    const response = await auth.awsFetch('/api/v1/users/', 'PUT', body);
    return response.json();
  };
  delete = async (auth) => {
    const response = await auth.awsFetch('/api/v1/users/me', 'DELETE');
    return response.json();
  };

  getResume = async (auth) => {
    const response = await auth.awsFetch('/api/v1/users/me/resume', 'GET');
    return response.json();
  };

  postEducation = async (auth, body) => {
    const response = await auth.awsFetch('/api/v1/users/me/education', 'POST', body);
    return response.json();
  };

  putEducation = async (auth, eduID, body) => {
    // eslint-disable-next-line no-undef
    const response = await auth.awsFetch(`/api/v1/users/me/education/${eduID}`, 'PUT', body);
    return response.json();
  };

  deleteEducation = async (auth, eduID) => {
    const response = await auth.awsFetch(`/api/v1/users/me/education/${eduID}`, 'DELETE');
    return response.json();
  };

  postEmployment = async (auth, body) => {
    const response = await auth.awsFetch('/api/v1/users/me/employment', 'POST', body);
    return response.json();
  };

  putEmployment = async (auth, empID, body) => {
    const response = await auth.awsFetch(`/api/v1/users/me/employment/${empID}`, 'PUT', body);
    return response.json();
  };

  deleteEmployment = async (auth, empID) => {
    const response = await auth.awsFetch(`/api/v1/users/me/employment/${empID}`, 'DELETE');
    return response.json();
  };
}

export default UserService;
