import React from 'react';
// Mobile navigation:
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
// Web navigation:
import { createStackNavigator as createStackNavigatorOld } from 'react-navigation-stack';
import { createBrowserApp } from '@react-navigation/web';

import WelcomePage from '../containers/welcome-page';
import SignUpPage from '../containers/sign-up-page';
import ConfirmEmailPage from '../containers/confirm-email-page';
import SignInPage from '../containers/sign-in-page';
import ForgotPasswordPage from '../containers/forgot-password-page';
import ForgotPasswordSubmitPage from '../containers/forgot-password-submit-page';
import { isWeb } from '../../environment';

// NOTE: in this solution the navigation is duplicated,
// but couldn't get it working otherwise with the 5.x
// version of the @react-navigation package
let container = null;
if (isWeb) {
  const UnauthenticatedStackNavigation = createStackNavigatorOld(
    {
      WelcomePage: {
        screen: WelcomePage,
        navigationOptions: {
          headerShown: false,
        },
      },
      SignUpPage: {
        screen: SignUpPage,
        navigationOptions: {
          headerShown: false,
        },
      },
      ConfirmSignUpPage: {
        screen: ConfirmEmailPage,
        navigationOptions: {
          headerShown: false,
        },
      },
      LogInPage: {
        screen: SignInPage,
        navigationOptions: {
          headerShown: false,
        },
      },
      ForgotPasswordPage: {
        screen: ForgotPasswordPage,
        navigationOptions: {
          headerShown: false,
        },
      },
      ForgotPasswordSubmitPage: {
        screen: ForgotPasswordSubmitPage,
        navigationOptions: {
          headerShown: false,
        },
      },
    },
    {
      initialRouteName: 'WelcomePage',
    }
  );

  container = createBrowserApp(UnauthenticatedStackNavigation);
} else {
  const Stack = createStackNavigator();

  class UnauthenticatedNavigationMobile extends React.Component {
    render() {
      return (
        <NavigationContainer>
          <Stack.Navigator initialRouteName="WelcomePage">
            <Stack.Screen
              name="WelcomePage"
              component={WelcomePage}
              options={{
                title: 'UIHelp.org',
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="SignUpPage"
              component={SignUpPage}
              options={{
                title: 'Sign up',
                headerShown: true,
              }}
            />
            <Stack.Screen
              name="ConfirmSignUpPage"
              component={ConfirmEmailPage}
              options={{
                title: 'Confirm email',
                headerShown: true,
              }}
            />
            <Stack.Screen
              name="LogInPage"
              component={SignInPage}
              options={{
                title: 'Log in',
                headerShown: true,
              }}
            />
            <Stack.Screen
              name="ForgotPasswordPage"
              component={ForgotPasswordPage}
              options={{
                title: 'Forgot password',
                headerShown: true,
              }}
            />
            <Stack.Screen
              name="ForgotPasswordSubmitPage"
              component={ForgotPasswordSubmitPage}
              options={{
                title: 'Reset password',
                headerShown: true,
              }}
            />
          </Stack.Navigator>
        </NavigationContainer>
      );
    }
  }

  container = UnauthenticatedNavigationMobile;
}

export default container;
