import React from 'react';
import { Text, View, TextInput, StyleSheet } from 'react-native';
import { color } from '../../../helper/theme-helper';
import PropTypes from 'prop-types';

const InputText = (props) => {
  const {
    lable = '',
    value = '',
    onChangeText = null,
    placeholder = '',
    placeholderColor = color.gray,
    inputTextProps = null,
    labelStyle = null,
    inputStyle = null,
  } = props;
  const { labelText, textInput } = styles;
  return (
    <View>
      <Text style={[labelText, labelStyle]} accessibilityRole="text">
        {lable}
      </Text>
      <TextInput
        onChangeText={onChangeText}
        value={value.trimStart().charAt(0).toUpperCase() + value.slice(1)}
        placeholder={placeholder}
        placeholdercolor={placeholderColor}
        style={[textInput, inputStyle]}
        autoCapitalize={'none'}
        autoCorrect={false}
        {...inputTextProps}
      />
    </View>
  );
};
const styles = StyleSheet.create({
  labelText: {
    marginTop: 20,
    fontSize: 13,
    fontWeight: 'bold',
    marginBottom: 5,
    color: color.darkGray,
  },
  textInput: {
    height: 42,
    fontSize: 14,
    borderColor: color.gray,
    borderWidth: 1,
    borderRadius: 5,
    color: '#000',
    paddingHorizontal: 10,
  },
});

InputText.propTypes = {
  lable: PropTypes.string,
  value: PropTypes.string,
  onChangeText: PropTypes.func,
  placeholder: PropTypes.string,
  placeholderColor: PropTypes.string,
  inputTextProps: PropTypes.object,
  labelStyle: PropTypes.object,
  inputStyle: PropTypes.object,
};

export { InputText };
