import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '../../authentication';
import SignUp from '../../components/pages/sign-up';

class SignUpPage extends React.Component {
  static propTypes = {
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
    }).isRequired,
    auth: PropTypes.shape({
      signUp: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
  }

  logIn = () => {
    const { navigation } = this.props;
    navigation.navigate('LogInPage');
  };

  signUp = async (email, newPassword) => {
    this.setState({ loading: true });
    const { auth, navigation } = this.props;
    const { error } = await auth.signUp(email, newPassword);
    if (error) {
      this.setState({ loading: false, error });
    } else {
      this.setState({ loading: false, error: null });
      navigation.navigate('ConfirmSignUpPage');
    }
  };

  render() {
    const { loading, error } = this.state;
    return <SignUp loading={loading} error={error} logIn={this.logIn} signUp={this.signUp} />;
  }
}

export { SignUpPage };
export default withAuth(SignUpPage);
