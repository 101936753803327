import React from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import PropTypes from 'prop-types';
import awsconfig from '../../aws-exports';
import logger from './logger';

Amplify.configure(awsconfig);

class Authenticator extends React.Component {
  static propTypes = {
    Loading: PropTypes.node,
    AuthenticatedContent: PropTypes.node,
    UnauthenticatedContent: PropTypes.node,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      user: null,
      siginFailure: false,
    };

    Hub.listen('auth', (data) => {
      const { payload } = data;
      this.onAuthEvent(payload);
      console.info(
        'A new auth event has happened: ',
        data.payload.data.username + ' has ' + data.payload.event
      );
    });
  }

  checkIfUserIsSignedIn() {
    console.info('checkIfUserIsSignedIn ...');
    Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then((user) => {
        console.info('checkIfUserIsSignedIn: user is signed in');
        this.setState({
          loading: false,
          user,
          siginFailure: false,
        });
      })
      .catch((err) => {
        if (err !== 'not authenticated') {
          console.info('checkIfUserIsSignedIn failed', err);
        }
        console.info('checkIfUserIsSignedIn: user is not signed in');
        this.setState({
          loading: false,
          user: null,
        });
      });
  }

  setCurrentUser() {
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    }).then((user) => {
      this.setState({
        user,
        siginFailure: false,
      });
    });
  }

  onAuthEvent(payload) {
    console.info('onAuthEvent', payload);
    switch (payload.event) {
      case 'signIn':
        logger.info('user signed in');
        this.setCurrentUser();
        break;
      case 'signUp':
        logger.info('user signed up');
        this.setCurrentUser();
        break;
      case 'signOut':
        logger.info('user signed out');
        this.setState({
          user: null,
        });
        break;
      case 'signIn_failure':
        this.setState({
          siginFailure: null,
        });
        logger.info('user sign in failed');
        break;
      case 'configured':
        this.checkIfUserIsSignedIn();
    }
  }

  componentDidMount() {
    this.checkIfUserIsSignedIn();
  }

  render() {
    const { loading, user, siginFailure } = this.state;
    const { Loading, AuthenticatedContent, UnauthenticatedContent } = this.props;
    if (loading) {
      return Loading;
    }
    if (user) {
      const AuthenticatedContentWithProps = React.cloneElement(AuthenticatedContent, { user });
      return AuthenticatedContentWithProps;
    }
    const UnauthenticatedContentWithProps = React.cloneElement(UnauthenticatedContent, {
      siginFailure,
    });
    return UnauthenticatedContentWithProps;
  }
}

export { Authenticator };
