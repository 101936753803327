import React, { useRef, useState, useEffect } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Picker } from 'react-native';
import { InputText, CustomDatePicker } from '../../controls';
import { AppButton } from '../../layouts';
import { color, isIOS } from '../../../helper/theme-helper';
import { Ionicons } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import states from '../onboarding/states';
import countries from './countries';
import moment from 'moment';
import { DATE_FORMAT } from '../../../helper/strings';

export const Education = (props) => {
  const { saveClick, deleteClick, profileData } = props;
  const arrLength = 6;
  const inputRefs = [];
  for (let i = 0; i < arrLength; i++) {
    inputRefs[i] = useRef(null);
  }
  const [data, setData] = useState({
    institution: '',
    program: '',
    city: '',
    stateCode: '',
    countryCode: '',
    startDate: moment().format(DATE_FORMAT),
    endDate: moment().format(DATE_FORMAT),
    isCurrent: false,
  });
  useEffect(() => {
    if (profileData) {
      setData({
        institution: profileData.institution || '',
        program: profileData.program || '',
        city: profileData.city || '',
        stateCode: profileData.stateCode,
        countryCode: profileData.countryCode,
        startDate: moment(profileData.startDate).format(DATE_FORMAT) || '',
        endDate: profileData.endDate
          ? moment(profileData.endDate).format(DATE_FORMAT)
          : moment().format(DATE_FORMAT),
        isCurrent: profileData.currentlyEnrolled || false,
      });
    }
  }, []);

  const [dateError, setError] = useState({
    sDate: false,
    eDate: false,
    showError: false,
  });

  const { container, titleText, labelText, checkBox, btnContainer, btnText } = styles;

  const onChangeText = (key, value) => {
    if (key === 'startDate' || key === 'endDate') {
      const errorKey = key === 'startDate' ? 'sDate' : 'eDate';
      const test = value.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
      setError({ ...dateError, [errorKey]: test === null });
    }
    setData({ ...data, [key]: value });
  };

  const onSubmitEditing = (nextIndex) => {
    inputRefs[nextIndex].current && inputRefs[nextIndex].current.focus();
  };

  const save = () => {
    if (!moment(data.startDate).isBefore(data.endDate) && data.startDate !== data.endDate) {
      return alert('Please, verify end date with start date.');
    }
    if (
      data.institution === '' ||
      data.program === '' ||
      data.city === '' ||
      data.stateCode === '' ||
      data.countryCode === '' ||
      data.startDate === ''
    ) {
      return alert('Please enter all fields.');
    }
    const newProfile = {
      ...profileData,
      institution: data.institution || '',
      program: data.program || '',
      city: data.city || '',
      stateCode: data.stateCode,
      countryCode: data.countryCode,
      startDate: data.startDate,
      endDate: data.endDate || null,
      currentlyEnrolled: data.isCurrent,
    };
    saveClick(newProfile);
  };

  const deleteEdu = () => {
    deleteClick(profileData.id);
  };

  const renderCheckBox = () => {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 10 }}>
        <TouchableOpacity
          style={checkBox}
          activeOpacity={0.6}
          onPress={() => onChangeText('isCurrent', !data.isCurrent)}
        >
          {data.isCurrent && <Ionicons name={'md-checkmark'} size={20} color={color.darkGray} />}
        </TouchableOpacity>
        <Text style={labelText}>Currently Enrolled</Text>
      </View>
    );
  };

  return (
    <View style={container}>
      <Text style={titleText}>Education</Text>
      <InputText
        lable={'Institution'}
        value={data.institution}
        placeholder={'Institution'}
        onChangeText={(text) => onChangeText('institution', text)}
        inputTextProps={{
          ref: inputRefs[0],
          returnKeyType: 'next',
          onSubmitEditing: () => {
            onSubmitEditing(1);
          },
        }}
      />
      <InputText
        lable={'Degree'}
        value={data.program}
        placeholder={'Degree'}
        onChangeText={(text) => onChangeText('program', text)}
        inputTextProps={{
          ref: inputRefs[1],
          returnKeyType: 'next',
          onSubmitEditing: () => {
            onSubmitEditing(2);
          },
        }}
      />
      <InputText
        lable={'City'}
        value={data.city}
        placeholder={'City'}
        onChangeText={(text) => onChangeText('city', text)}
        inputTextProps={{
          ref: inputRefs[2],
          returnKeyType: 'next',
          onSubmitEditing: () => {
            onSubmitEditing(3);
          },
        }}
      />
      <Text style={styles.textInputLabel} accessibilityRole="text">
        Select state:
      </Text>
      <Picker
        onValueChange={(x) => onChangeText('stateCode', x)}
        selectedValue={data.stateCode}
        style={[!isIOS ? { height: 42 } : null]}
      >
        <Picker.Item label="" value="" />
        {states.map((v) => (
          <Picker.Item label={v.name} value={v.abbreviation} key={v.abbreviation} />
        ))}
      </Picker>
      <Text style={styles.textInputLabel} accessibilityRole="text">
        Select country:
      </Text>
      <Picker
        onValueChange={(x) => onChangeText('countryCode', x)}
        selectedValue={data.countryCode}
        style={[!isIOS ? { height: 42 } : null]}
      >
        <Picker.Item label="" value="" />
        {countries.map((v) => (
          <Picker.Item label={v.name} value={v.abbreviation} key={v.abbreviation} />
        ))}
      </Picker>
      <Text style={styles.textInputLabel} accessibilityRole="text">
        Start Date
      </Text>
      <CustomDatePicker
        date={data.startDate}
        changeDate={(date) => onChangeText('startDate', date)}
      />
      <Text style={styles.textInputLabel} accessibilityRole="text">
        End Date
      </Text>
      <CustomDatePicker
        date={data.endDate}
        changeDate={(date) => onChangeText('endDate', date)}
        disable={data.isCurrent}
        minDate={data.startDate}
      />
      {renderCheckBox()}
      <AppButton
        containerStyle={[{ backgroundColor: color.midBlue }, btnContainer]}
        titleStyle={btnText}
        title={'Save'}
        onPress={save}
      />
      {profileData && (
        <AppButton
          containerStyle={[{ backgroundColor: color.red }, btnContainer]}
          titleStyle={btnText}
          title={'Delete'}
          onPress={deleteEdu}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: color.white,
    paddingHorizontal: 16,
    marginBottom: 25,
  },
  titleText: {
    fontSize: 28,
    fontWeight: 'bold',
    paddingVertical: 30,
    alignSelf: 'center',
    color: color.darkGray,
  },
  labelText: {
    fontSize: 15,
    fontWeight: 'bold',
    color: color.darkGray,
    lineHeight: 17,
  },
  checkBox: {
    height: 20,
    width: 20,
    backgroundColor: color.lightGray,
    borderRadius: 3,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnContainer: {
    borderRadius: 5,
    marginTop: 15,
  },
  btnText: {
    color: color.white,
    fontWeight: 'bold',
  },
  textInputLabel: {
    marginTop: 15,
    fontWeight: 'bold',
    marginBottom: 6,
    color: color.darkGray,
  },
  errorText: {
    color: color.red,
    fontWeight: 'bold',
  },
});

Education.propTypes = {
  profileData: PropTypes.object,
  saveClick: PropTypes.func,
  deleteClick: PropTypes.func,
};
