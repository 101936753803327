import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, ActivityIndicator, Image, Text, TextInput, View } from 'react-native';
import { color, font, isWeb } from '../../../helper/theme-helper';
import states from './states';
import { AppButton } from '../../layouts';
import { UIHelpLogo } from '../../assets';
import Picker from 'react-native-picker-select';

class Onboarding extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    save: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      firstNameError: '',
      lastName: '',
      lastNameError: '',
      stateCode: '',
      stateCodeError: '',
    };
  }

  isTextlValid = (text) => {
    return text.length > 0;
  };

  setFirstName = (firstName) => {
    const firstNameError = this.isTextlValid(firstName) ? '' : 'Please enter your first name';
    this.setState({ firstName, firstNameError });
  };

  setLastName = (lastName) => {
    const lastNameError = this.isTextlValid(lastName) ? '' : 'Please enter your last name';
    this.setState({ lastName, lastNameError });
  };

  setStateCode = (stateCode) => {
    const stateCodeError = stateCode !== '' ? '' : 'Please select the state you plan to work in';
    this.setState({ stateCode, stateCodeError });
  };

  render() {
    const { loading, error, save, signOut } = this.props;
    const {
      firstName,
      firstNameError,
      lastName,
      lastNameError,
      stateCode,
      stateCodeError,
    } = this.state;
    return (
      <View style={styles.container}>
        {error ? (
          <View style={styles.errorBox}>
            <Text style={styles.errorText} accessibilityRole="text">
              Connection failure
            </Text>
          </View>
        ) : null}
        <View
          style={{
            alignItems: 'center',
            width: '100%',
            marginTop: isWeb ? 0 : 10,
          }}
        >
          <Image
            source={UIHelpLogo}
            style={{
              width: 179,
              height: 68,
              resizeMode: 'contain',
            }}
          />
        </View>
        <View style={{ alignItems: 'center', justifyContent: 'center', marginVertical: 30 }}>
          <Text style={{ fontSize: 24, color: color.midBlue, fontWeight: 'bold' }}>
            Welcome to Syrg
          </Text>
        </View>
        <Text style={styles.textInputLabel} accessibilityRole="text">
          Your first name:
        </Text>
        <TextInput
          onChangeText={this.setFirstName}
          value={firstName}
          placeholder={'First name'}
          style={styles.textInput}
          autoCapitalize={'none'}
          autoCorrect={false}
          autoFocus={true}
          keyboardType={'default'}
          textContentType={'givenName'}
          disabled={!!loading}
        />
        <Text style={styles.textInputError} accessibilityRole="text">
          {firstNameError}
        </Text>
        <Text style={styles.textInputLabel} accessibilityRole="text">
          Your last name:
        </Text>
        <TextInput
          onChangeText={this.setLastName}
          value={lastName}
          placeholder={'Last name'}
          style={styles.textInput}
          autoCapitalize={'none'}
          autoCorrect={false}
          autoFocus={false}
          keyboardType={'default'}
          textContentType={'familyName'}
          disabled={!!loading}
        />
        <Text style={styles.textInputError} accessibilityRole="text">
          {lastNameError}
        </Text>
        <Text style={styles.textInputLabel} accessibilityRole="text">
          Select state:
        </Text>
        <View style={styles.picker}>
          <Picker
            onValueChange={this.setStateCode}
            selectedValue={stateCode}
            placeholder={{}}
            useNativeAndroidPickerStyle={false}
            items={[
              { label: '', value: '' },
              ...states.map((v) => ({ label: v.name, value: v.abbreviation })),
            ]}
            disabled={!!loading}
          />
        </View>
        <Text style={styles.textInputError} accessibilityRole="text">
          {stateCodeError}
        </Text>

        <View style={styles.backgroundShape} />
        <View style={styles.buttonContainer}>
          <View style={styles.signupButton}>
            <AppButton
              title="Complete signup"
              onPress={() => save(firstName, lastName, stateCode)}
              disabled={
                !!loading ||
                firstNameError !== '' ||
                lastNameError !== '' ||
                stateCodeError !== '' ||
                firstName === '' ||
                lastName === '' ||
                stateCode === ''
              }
            />
          </View>
          {firstNameError !== '' || lastNameError !== '' || stateCodeError !== '' ? (
            <Text style={styles.textInputError} accessibilityRole="text">
              Please correct the errors above
            </Text>
          ) : null}
          {loading ? <ActivityIndicator size="small" /> : null}
          <Text style={styles.linkButton} accessibilityRole="link" onPress={() => signOut()}>
            Sign out
          </Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20,
  },
  backgroundShape: {
    width: '100%',
    height: 270,
    zIndex: -1,
    position: 'absolute',
    backgroundColor: color.midBlue,
    borderTopLeftRadius: 300,
    borderTopRightRadius: 300,
    left: 0,
    transform: [
      {
        scaleX: 1.7,
      },
    ],
    bottom: -20,
  },
  baseText: {
    // fontFamily: 'Lato_Regular',
    paddingBottom: 6,
  },
  titleText: {
    fontSize: 30,
    fontWeight: 'bold',
    // fontFamily: 'Lato_Bold',
    paddingTop: 10,
    paddingBottom: 20,
  },
  linkButton: {
    fontSize: 16,
    fontFamily: font.Lato_Regular,
    paddingLeft: 10,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    textAlign: 'center',
    color: color.white,
    textDecorationLine: 'underline',
  },
  textInput: {
    // fontFamily: 'Lato_Bold',
    height: 42,
    width: '100%',
    borderColor: '#cccccc',
    backgroundColor: '#ffffff',
    borderWidth: 1,
    color: '#000000',
    padding: 5,
  },
  textInputLabel: {
    // fontFamily: 'Lato_Regular',
    marginTop: 15,
    marginBottom: 6,
    color: '#474747',
  },
  textInputError: {
    // fontFamily: 'Lato_Bold',
    marginTop: 6,
    marginBottom: 15,
    marginLeft: 5,
    color: '#CC0000',
  },
  errorBox: {
    width: '100%',
    backgroundColor: '#990000',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 10,
    margin: 5,
  },
  errorText: {
    fontSize: 16,
    color: '#ffffff',
  },
  picker: {
    height: 42,
    borderColor: '#cccccc',
    borderWidth: 1,
    // borderRadius: 5,
    justifyContent: 'center',
    paddingLeft: 5,
    backgroundColor: '#fff',
  },
  signupButton: {
    alignSelf: 'stretch',
    textAlign: 'center',
    marginBottom: 20,
    fontSize: 18,
  },
  buttonContainer: {
    flex: 1,
    zIndex: 2,
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 40,
  },
});

export default Onboarding;
