import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '../../authentication';
import ForgotPassword from '../../components/pages/forgot-password';

class ForgotPasswordPage extends React.Component {
  static propTypes = {
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
    }).isRequired,
    auth: PropTypes.shape({
      forgotPassword: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
  }

  forgotPasswordClick = async (email) => {
    this.setState({ loading: true });
    const { auth, navigation } = this.props;
    const { error } = await auth.forgotPassword(email);
    if (error) {
      this.setState({ loading: false, error });
    } else {
      this.setState({ loading: false, error: null });
      navigation.navigate('ForgotPasswordSubmitPage');
    }
  };

  render() {
    const { loading, error } = this.state;
    return (
      <ForgotPassword
        loading={loading}
        error={error}
        forgotPasswordClick={this.forgotPasswordClick}
      />
    );
  }
}

export { ForgotPasswordPage };
export default withAuth(ForgotPasswordPage);
