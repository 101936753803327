import React from 'react';
import { StyleSheet, Image, Text, View } from 'react-native';
import { SafeArea, AppButton } from '../../layouts';
import { UIJobLogo } from '../../assets';
import PropTypes from 'prop-types';
import { color, isWeb, normalize } from '../../../helper/theme-helper';
import * as Font from 'expo-font';

let customFonts = {
  // eslint-disable-next-line no-undef
  Lato: require('../../../../assets/fonts/Lato-Bold.ttf'),
};
export default class Welcome extends React.Component {
  static propTypes = {
    signUp: PropTypes.func.isRequired,
    logIn: PropTypes.func.isRequired,
  };
  state = {
    fontsLoaded: false,
  };
  async _loadFontsAsync() {
    await Font.loadAsync(customFonts);
    this.setState({ fontsLoaded: true });
  }
  componentDidMount() {
    this._loadFontsAsync();
  }
  render() {
    const { signUp, logIn } = this.props;
    if (this.state.fontsLoaded) {
      return (
        <SafeArea>
          <View style={styles.container}>
            <View style={styles.upperFlex}>
              <View
                style={{
                  alignItems: 'center',
                  width: '100%',
                  marginTop: 100,
                }}
              >
                <Image
                  source={UIJobLogo}
                  style={{
                    width: 179,
                    height: 100,
                  }}
                  resizeMode={'contain'}
                />
              </View>
              <View style={styles.welcomeTextContainer}>
                <Text style={styles.titleText}>Applying for a job has never been easier</Text>
                <Text style={styles.baseText}>
                  <Text>We have 300k+ jobs hiring hourly workers, and we’ll help you apply</Text>
                </Text>
              </View>
            </View>
            <View style={styles.buttonContainer}>
              <AppButton
                containerStyle={{
                  backgroundColor: color.midBlue,
                  width: isWeb ? 341 : normalize(341),
                  height: isWeb ? 50 : 63,
                  marginBottom: isWeb ? 40 : normalize(40),
                  borderRadius: 32,
                  marginTop: isWeb ? normalize(5) : normalize(176),
                }}
                title="Sign Up"
                titleStyle={{ fontFamily: 'Lato', color: color.white, fontSize: 18 }}
                onPress={() => signUp()}
              />
              <Text style={styles.linkButton} accessibilityRole="link" onPress={() => logIn()}>
                Log in
              </Text>
            </View>
          </View>
        </SafeArea>
      );
    } else {
      return <SafeArea />;
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20,
    marginTop: 60,
  },
  baseText: {
    fontSize: 18,
    paddingBottom: 6,
    textAlign: 'center',
    color: color.gray,
    fontFamily: 'Lato',
  },
  titleText: {
    fontSize: 18,
    color: color.midBlue,
    fontWeight: 'bold',
    paddingTop: 50,
    paddingBottom: 20,
    textAlign: 'center',
    fontFamily: 'Lato',
  },
  linkButton: {
    fontSize: 18,
    lineHeight: 22,
    color: color.midBlue,
    fontFamily: 'Lato',
  },
  welcomeTextContainer: {
    marginTop: isWeb ? '2%' : '25%',
    width: 280,
    alignSelf: 'center',
    fontFamily: 'Lato',
  },
  buttonContainer: {
    flex: 1,
    zIndex: 2,
    justifyContent: isWeb ? 'center' : 'flex-start',
    alignItems: 'center',
    marginBottom: isWeb ? 40 : normalize(40),
  },
  upperFlex: {
    flex: 1,
    justifyContent: 'center',
  },
});
