import { observable, runInAction, decorate } from 'mobx';
import UserService from '../services/user-service';

class UserStore {
  constructor() {
    this.userService = new UserService();
  }
  userData = {
    model: {},
  };
  resumeData = {
    model: {},
  };

  //status = "initial";

  getUser = async (auth) => {
    try {
      const data = await this.userService.get(auth);
      runInAction(() => {
        this.userData = {
          model: data,
        };
      });
      return true;
    } catch (error) {
      console.error('getUser error', error);
      runInAction(() => {
        this.status = 'error';
      });
      return false;
    }
  };
  registerUser = async (auth, body) => {
    try {
      const data = await this.userService.post(auth, body);
      if (data.status === 201) {
        runInAction(() => {
          this.status = 'success';
        });
      }
      return true;
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
    return false;
  };
  // eslint-disable-next-line no-unused-vars
  updateUser = async (auth, user) => {
    try {
      const data = await this.userService.put(auth, user);
      runInAction(() => {
        this.userData = {
          model: data,
        };
      });
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
  };
  // deleteUser = async (auth) => {
  //     try {
  //         const response = await this.userService.delete(auth);
  //         if (response.status === 204) {
  //             runInAction(() => {
  //                 this.status = "success";
  //             })
  //         }
  //     } catch (error) {
  //         runInAction(() => {
  //             this.status = "error";
  //         });
  //     }
  // }

  getResume = async (auth) => {
    try {
      const data = await this.userService.getResume(auth);
      runInAction(() => {
        this.resumeData = {
          model: data,
        };
      });
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  addEducation = async (auth, body) => {
    try {
      const data = await this.userService.postEducation(auth, body);
      runInAction(() => {
        this.resumeData = {
          model: data,
        };
      });
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
  };

  updateEducation = async (auth, id, body) => {
    try {
      const response = await this.userService.putEducation(auth, id, body);
      runInAction(() => {
        this.resumeData = {
          model: response,
        };
      });
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
  };

  deleteEducation = async (auth, eduID) => {
    try {
      const response = await this.userService.deleteEducation(auth, eduID);
      runInAction(() => {
        this.resumeData = {
          model: response,
        };
      });
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
  };

  addEmployment = async (auth, body) => {
    try {
      const data = await this.userService.postEmployment(auth, body);
      runInAction(() => {
        this.resumeData = {
          model: data,
        };
      });
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  updateEmployment = async (auth, id, body) => {
    try {
      const response = await this.userService.putEmployment(auth, id, body);
      runInAction(() => {
        this.resumeData = {
          model: response,
        };
      });
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
  };

  deleteEmployment = async (auth, empID) => {
    try {
      const response = await this.userService.deleteEmployment(auth, empID);
      runInAction(() => {
        this.resumeData = {
          model: response,
        };
      });
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
  };
}

decorate(UserStore, {
  userData: observable,
  resumeData: observable,
});

export default new UserStore();
