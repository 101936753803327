import React from 'react';
import * as Font from 'expo-font';
import { AppLoading } from 'expo';
import { isWeb } from '../../environment';
import PropTypes from 'prop-types';

const fontData = {
  // eslint-disable-next-line no-undef
  Lato_Bold: require('../../assets/fonts/Lato-Bold.ttf'),
  // eslint-disable-next-line no-undef
  Lato_Regular: require('../../assets/fonts/Lato-Regular.ttf'),
};

export default class AppLoader extends React.Component {
  static propTypes = {
    children: PropTypes.element,
  };

  state = {
    appIsReady: false,
  };

  cacheFonts = (fonts) => {
    return fonts.map((font) => Font.loadAsync(font));
  };

  loadAssets = async () => {
    const fontAssets = this.cacheFonts([fontData]);
    await Promise.all([...fontAssets]);
    // this.setState({ appIsReady: true })
  };
  render() {
    if (!isWeb && !this.state.appIsReady) {
      return (
        <AppLoading
          startAsync={this.loadAssets}
          onFinish={() => this.setState({ appIsReady: true })}
          onError={console.warn}
        />
      );
    }
    return this.props.children;
  }
}
