import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, FlatList, Text } from 'react-native';
import { ShiftCardInfo, ShiftCard, AppButton } from '../../layouts';
import { color } from '../../../helper/theme-helper';
import { observer, inject, Observer } from 'mobx-react';
import Loading from '../loading';

class Home extends Component {
  static propTypes = {
    onJobClick: PropTypes.func.isRequired,
    onJobSearchClicked: PropTypes.func.isRequired,
    JobStore: PropTypes.object.isRequired,
  };

  renderItemInfo = ({ item }) => {
    return (
      <Observer>
        {() => {
          return (
            <ShiftCardInfo
              date={item.createdAt}
              title={item.title}
              location={item.locations[0]}
              company={item.company}
              jobType={item.jobType}
              isNew={item.lastAppliedAt === null && item.lastViewedAt === null}
              isApplied={item.lastAppliedAt !== null}
              isViewed={item.lastAppliedAt === null && item.lastViewedAt !== null}
              onPress={() => {
                this.props.onJobClick(
                  item.id,
                  item.title,
                  item.company,
                  item.description,
                  item.lastViewedAt,
                  item.externalUrl,
                  !!item.lastAppliedAt
                );
              }}
            />
          );
        }}
      </Observer>
    );
  };

  renderItems = ({ item }) => {
    return (
      <Observer>
        {() => {
          return (
            <ShiftCard
              date={item.createdAt}
              company={item.company}
              location={item.locations[0]}
              title={item.title}
              onPress={() => {
                this.props.onJobClick(
                  item.id,
                  item.title,
                  item.company,
                  item.description,
                  item.lastViewedAt,
                  item.externalUrl,
                  !!item.lastAppliedAt
                );
              }}
            />
          );
        }}
      </Observer>
    );
  };

  render() {
    const {
      JobStore: { lastAppliedData, jobsData },
    } = this.props;
    const { model: data, loading: jobsLoading } = jobsData;
    const { model: appliedData, loading: appliedLoading } = lastAppliedData;
    const length = appliedData.length;
    if (!jobsLoading && !appliedLoading) {
      return (
        <View style={styles.container}>
          <View style={{ marginVertical: 20 }}>
            <Text style={{ fontSize: 14, color: color.gray, fontWeight: 'bold' }}>
              My Applications
            </Text>
            {length === 0 && (
              <View style={{ alignItems: 'center' }}>
                <Text
                  style={{ fontSize: 14, color: color.gray, fontWeight: 'bold', marginTop: 40 }}
                >
                  You have no applications yet!
                </Text>
                <Text style={{ fontSize: 14, color: color.gray, marginBottom: 20 }}>
                  Here are some jobs in your area:
                </Text>
                <AppButton
                  icon
                  containerStyle={{
                    backgroundColor: color.midBlue,
                    width: 141,
                    height: 32,
                    marginBottom: 40,
                  }}
                  title="Job search"
                  titleStyle={{
                    color: color.white,
                    fontWeight: 'bold',
                    fontSize: 15,
                  }}
                  onPress={this.props.onJobSearchClicked}
                />
              </View>
            )}
            {length === 0 && (
              <Text style={{ fontSize: 14, color: color.gray, fontWeight: 'bold' }}>
                Recommended Jobs
              </Text>
            )}
          </View>
          <FlatList
            data={length === 0 ? data.slice(0, 10) : appliedData}
            renderItem={length === 0 ? this.renderItemInfo : this.renderItems}
            ItemSeparatorComponent={() => {
              return <View style={{ height: 5 }} />;
            }}
            keyExtractor={(item, index) => {
              return index.toString();
            }}
          />
        </View>
      );
    } else {
      return <Loading />;
    }
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingRight: 17,
    paddingLeft: 17,
    backgroundColor: color.white,
    justifyContent: 'center',
  },
});

export default inject('JobStore')(observer(Home));
