import React from 'react';
import AppLoader from './src/app-loader';
import { Authenticator } from './src/authentication';
import Loading from './src/components/pages/loading';
import AuthenticatedNavigation from './src/navigation/authenticated';
import UnauthenticatedNavigation from './src/navigation/unauthenticated';
import { Provider } from 'mobx-react';
import JobStore from './src/stores/job-store';
import UserStore from './src/stores/user-store';
import QuestionsStore from './src/stores/questions-store';
import OnboardingPage from './src/containers/onboarding-page';

function App() {
  return (
    <AppLoader>
      <Provider UserStore={UserStore} JobStore={JobStore} QuestionsStore={QuestionsStore}>
        <Authenticator
          Loading={<Loading />}
          AuthenticatedContent={
            <OnboardingPage>
              <AuthenticatedNavigation />
            </OnboardingPage>
          }
          UnauthenticatedContent={<UnauthenticatedNavigation />}
        />
      </Provider>
    </AppLoader>
  );
}

export default App;
