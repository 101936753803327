import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import PropTypes from 'prop-types';
import { color } from '../../helper/theme-helper';

const SafeArea = (props) => {
  const { style, top = true, bottom = false } = props;
  const insets = useSafeArea();
  return (
    <View
      style={[
        styles.container,
        style,
        top && { paddingTop: insets.top },
        bottom && { paddingBottom: insets.bottom },
      ]}
    >
      {props.children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: color.white,
  },
});

SafeArea.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.array]),
  style: PropTypes.object,
  top: PropTypes.bool,
  bottom: PropTypes.bool,
};

export { SafeArea };
