import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '../../authentication';
import Feedback from '../../components/pages/feedback';
import { observer, inject } from 'mobx-react';

class FeedbackPage extends React.Component {
  static propTypes = {
    navigation: PropTypes.any,
    QuestionsStore: PropTypes.object,
    auth: PropTypes.shape({
      signOut: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {
    this.getAllQuestions();
  }

  getAllQuestions = async () => {
    const { QuestionsStore, auth } = this.props;
    try {
      await QuestionsStore.getAllQuestions(auth);
    } catch (error) {
      console.error(error);
    }
  };

  onNewQuestion = () => {
    this.props.navigation.navigate('NewQuestionPage');
  };

  onListQuestion = (id) => {
    this.props.navigation.navigate('FaqDetailPage', { id });
  };

  toggleModal = (value = !this.state.showModal) => {
    this.setState({ showModal: value });
  };

  render() {
    return (
      <Feedback
        onNewQuestion={this.onNewQuestion}
        onListQuestion={this.onListQuestion}
        showModal={this.state.showModal}
        toggleModal={this.toggleModal}
      />
    );
  }
}

export { FeedbackPage };
export default inject('QuestionsStore')(observer(withAuth(FeedbackPage)));
