import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, ActivityIndicator, Text, TextInput, View } from 'react-native';
import { SafeArea, AppButton } from '../../layouts';
import { color, isWeb } from '../../../helper/theme-helper';

class ForgotPassword extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.any,
    forgotPasswordClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: '',
      password: '',
      passwordError: '',
    };
  }
  isEmailValid = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  setEmail = (email) => {
    const emailError = this.isEmailValid(email) ? '' : 'This is not a valid email address';
    this.setState({ email, emailError });
  };

  render() {
    const { forgotPasswordClick, loading, error } = this.props;
    const { email, emailError } = this.state;

    return (
      <SafeArea>
        <View style={styles.container}>
          {error ? (
            <View style={styles.errorBox}>
              <Text style={styles.errorText} accessibilityRole="text">
                There is a network problem, please retry
              </Text>
            </View>
          ) : null}
          <View style={{ justifyContent: 'center', marginVertical: 40 }}>
            <Text style={styles.baseText} accessibilityRole="text">
              Enter your email address and we&apos;ll send you a verification code to reset your
              password.
            </Text>
          </View>
          <Text style={styles.textInputLabel} accessibilityRole="text">
            Your email address:
          </Text>
          <TextInput
            onChangeText={this.setEmail}
            value={email}
            placeholder={'Email address'}
            style={styles.textInput}
            autoCapitalize={'none'}
            autoCorrect={false}
            autoFocus={true}
            keyboardType={'email-address'}
            textContentType={'emailAddress'}
            disabled={!!loading}
          />
          <Text style={styles.textInputError} accessibilityRole="text">
            {emailError}
          </Text>
          <View style={styles.buttonContainer}>
            <View style={styles.signupButton}>
              <AppButton
                containerStyle={{
                  backgroundColor: color.white,
                  width: '100%',
                  height: isWeb ? 50 : 63,
                  borderRadius: 32,
                }}
                title="Send email"
                onPress={() => forgotPasswordClick(email)}
                disabled={!!loading || emailError !== '' || email === ''}
              />
            </View>
            {emailError !== '' ? (
              <Text style={styles.textInputError} accessibilityRole="text">
                Please correct the errors above
              </Text>
            ) : null}
          </View>
          {loading ? <ActivityIndicator size="small" /> : null}
        </View>
        <View style={styles.backgroundShape} />
      </SafeArea>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20,
  },
  baseText: {
    paddingBottom: 6,
  },
  titleText: {
    fontSize: 30,
    fontWeight: 'bold',
    paddingTop: 10,
    paddingBottom: 20,
  },
  linkButton: {
    fontSize: 16,
    fontWeight: 'bold',
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: '#000000',
  },
  textInput: {
    height: 42,
    width: '100%',
    borderColor: '#cccccc',
    backgroundColor: '#ffffff',
    borderWidth: 1,
    color: '#000000',
    padding: 5,
  },
  textInputLabel: {
    marginTop: 15,
    marginBottom: 6,
    color: '#474747',
  },
  textInputError: {
    marginTop: 6,
    marginBottom: 15,
    marginLeft: 5,
    color: '#CC0000',
  },
  errorBox: {
    width: '100%',
    backgroundColor: '#990000',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 10,
    margin: 5,
  },
  errorText: {
    fontSize: 16,
    color: '#ffffff',
  },
  backgroundShape: {
    width: '100%',
    height: 270,
    zIndex: -1,
    position: 'absolute',
    backgroundColor: color.midBlue,
    borderTopLeftRadius: 300,
    borderTopRightRadius: 300,
    left: 0,
    transform: [
      {
        scaleX: 1.7,
      },
    ],
    bottom: -20,
  },
  buttonContainer: {
    flex: 1,
    zIndex: 2,
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 40,
  },
  signupButton: {
    alignSelf: 'stretch',
    textAlign: 'center',
    marginBottom: 20,
  },
});

export default ForgotPassword;
