class JobService {
  getSample = async (auth) => {
    const response = await auth.awsFetch('/api/v1/jobpostings/sample', 'GET');
    return response.json();
  };
  getPersonal = async (auth) => {
    const response = await auth.awsFetch('/api/v1/jobpostings/me', 'GET');
    return response.json();
  };
  getLastApplied = async (auth) => {
    const response = await auth.awsFetch('/api/v1/jobpostings/lastapplied', 'GET');
    return response.json();
  };
  putViewed = async (auth, id) => {
    const response = await auth.awsFetch(`/api/v1/jobpostings/${id}/viewed`, 'PUT');
    return response;
  };
  putApplied = async (auth, id) => {
    const response = await auth.awsFetch(`/api/v1/jobpostings/${id}/applied`, 'PUT');
    return response;
  };
}

export default JobService;
