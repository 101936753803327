import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '../../authentication';
import SignIn from '../../components/pages/sign-in';

class SignInPage extends React.Component {
  static propTypes = {
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
    }).isRequired,
    auth: PropTypes.shape({
      signIn: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
  }

  signUp = () => {
    const { navigation } = this.props;
    navigation.navigate('SignUpPage');
  };

  signIn = async (email, password) => {
    this.setState({ loading: true });
    const { auth } = this.props;
    const { error } = await auth.signIn(email, password);
    if (error) {
      this.setState({ loading: false, error });
    } else {
      this.setState({ loading: false, error: null });
    }
  };

  forgotPasswordClick = () => {
    const { navigation } = this.props;
    navigation.navigate('ForgotPasswordPage');
  };

  render() {
    const { loading, error } = this.state;
    return (
      <SignIn
        loading={loading}
        error={error}
        signIn={this.signIn}
        signUp={this.signUp}
        forgotPasswordClick={this.forgotPasswordClick}
      />
    );
  }
}

export { SignInPage };
export default withAuth(SignInPage);
