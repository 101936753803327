import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { color } from '../../helper/theme-helper';
import { Foundation } from '@expo/vector-icons';
import PropTypes from 'prop-types';

const AppButton = (props) => {
  const {
    containerStyle = null,
    titleStyle = null,
    onPress = null,
    title = '',
    icon = false,
  } = props;
  const { container, textStyle } = styles;
  return (
    <TouchableOpacity style={[container, containerStyle]} onPress={onPress}>
      <Text style={[textStyle, titleStyle]}>{title}</Text>
      {icon && (
        <Foundation name="shopping-bag" color={'#fff'} size={13} style={{ marginHorizontal: 5 }} />
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 40,
    backgroundColor: color.white,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 15,
    shadowOpacity: 1,
    shadowColor: '#2A3FBA13',
    shadowRadius: 5,
    shadowOffset: { height: 2, width: 0 },
    flexDirection: 'row',
  },
  textStyle: {
    fontSize: 18,
    color: color.midBlue,
  },
});

AppButton.propTypes = {
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  titleStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  onPress: PropTypes.func,
  title: PropTypes.string,
  icon: PropTypes.bool,
};

export { AppButton };
