import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import './swiper.css';

import { normalize, screenHeight, screenWidth } from '../../../helper/theme-helper';

export default class Swiper extends Component {
  static navigationOptions = {
    headerStyle: {
      backgroundColor: '#16a085',
      elevation: null,
    },
  };

  state = this.initState(this.props);
  initState(props) {
    // Get the total number of slides passed as children
    const total = props.children ? props.children.length || 1 : 0,
      index = total > 1 ? Math.min(props.index, total - 1) : 0,
      offset = screenWidth * index;

    const state = {
      total,
      index,
      offset,
      screenWidth,
      screenHeight,
    };
    this.internals = {
      isScrolling: false,
      offset,
    };

    return state;
  }
  render = ({ children } = this.props) => {
    return (
      <Carousel
        showArrows={true}
        swipeable={true}
        infiniteLoop
        emulateTouch
        showStatus={false}
        interval={6000}
        autoPlay={true}
      >
        {children.map((page, i) => (
          <View style={[styles.fullScreen, styles.slide]} key={i}>
            {page}
          </View>
        ))}
      </Carousel>
    );
  };
}

const styles = StyleSheet.create({
  fullScreen: {
    width: screenWidth,
    height: screenHeight - normalize(4),
  },
  container: {
    backgroundColor: 'transparent',
    position: 'relative',
  },
  slide: {
    backgroundColor: 'transparent',
  },
});
