import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, ActivityIndicator, Text, TextInput, View } from 'react-native';
import { SafeArea, AppButton } from '../../layouts';
import { color } from '../../../helper/theme-helper';

class ForgotPasswordSubmit extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.any,
    forgotPasswordSubmitClick: PropTypes.func.isRequired,
    resendEmailClick: PropTypes.func.isRequired,
    // By default we're not asking about the email as we already know it.
    // But on web it's possible to reload the email valiadtion page, and then
    // we lose this info. If we don't know the email anymore, then we're
    // asking about it again here, and using that for validation code-resending
    // and validation.
    email: PropTypes.any,
    emailResent: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: props.email || '',
      emailError: '',
      code: '',
      codeError: '',
      password: '',
      passwordError: '',
    };
  }

  isEmailValid = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  setEmail = (email) => {
    const emailError = this.isEmailValid(email) ? '' : 'This is not a valid email address';
    this.setState({ email, emailError });
  };

  isCodeValid = (code) => {
    return code.length > 0;
  };

  setCode = (code) => {
    const codeError = this.isCodeValid(code) ? '' : 'Enter the code from your email';
    this.setState({ code, codeError });
  };

  setPassword = (password) => {
    const passwordError = password.length >= 6 ? '' : 'At least 6 characters required';
    this.setState({ password, passwordError });
  };

  render() {
    const {
      email: emailProp,
      forgotPasswordSubmitClick,
      resendEmailClick,
      loading,
      error,
      emailResent,
    } = this.props;
    const { email, emailError, code, codeError, password, passwordError } = this.state;

    return (
      <SafeArea>
        <View style={styles.container}>
          {error ? (
            <View style={styles.errorBox}>
              <Text style={styles.errorText} accessibilityRole="text">
                Network communication failed, please retry.
              </Text>
            </View>
          ) : null}
          <Text style={styles.baseText} accessibilityRole="text">
            <Text>
              We {emailResent ? 'just re-sent the email' : 'sent an email'} to{' '}
              {emailProp || 'your account'}, it should arrive very soon. Please enter the validation
              code that you find in it.
            </Text>
          </Text>
          <Text
            style={styles.linkButton}
            accessibilityRole="link"
            onPress={() => resendEmailClick(email)}
            disabled={!!loading || email === '' || emailError !== ''}
          >
            Resend the validation email
          </Text>
          {emailProp ? null : (
            <React.Fragment>
              <Text style={styles.textInputLabel} accessibilityRole="text">
                Your email address:
              </Text>
              <TextInput
                onChangeText={this.setEmail}
                value={email}
                placeholder={'Email address'}
                style={styles.textInput}
                autoCapitalize={'none'}
                autoCorrect={false}
                autoFocus={true}
                keyboardType={'email-address'}
                textContentType={'emailAddress'}
                disabled={!!loading}
              />
              <Text style={styles.textInputError} accessibilityRole="text">
                {emailError}
              </Text>
            </React.Fragment>
          )}
          <Text style={styles.textInputLabel} accessibilityRole="text">
            Validation code:
          </Text>
          <TextInput
            onChangeText={this.setCode}
            value={code}
            placeholder={'Validation code'}
            style={styles.textInput}
            autoCapitalize={'none'}
            autoCorrect={false}
            autoFocus={true}
            keyboardType={'numeric'}
            textContentType={'oneTimeCode'}
            disabled={!!loading}
          />
          <Text style={styles.textInputError} accessibilityRole="text">
            {codeError}
          </Text>
          <Text style={styles.textInputLabel} accessibilityRole="text">
            Set a new password:
          </Text>
          <TextInput
            onChangeText={this.setPassword}
            value={password}
            placeholder={'New password'}
            style={styles.textInput}
            autoCapitalize={'none'}
            autoCorrect={false}
            autoFocus={false}
            keyboardType={'default'}
            textContentType={'newPassword'}
            secureTextEntry={true}
            disabled={!!loading}
          />
          <Text style={styles.textInputError} accessibilityRole="text">
            {passwordError}
          </Text>
          <View style={styles.buttonContainer}>
            <View style={styles.signupButton}>
              <AppButton
                title="Set new password and log in"
                onPress={() => forgotPasswordSubmitClick(email, code, password)}
                disabled={
                  !!loading ||
                  emailError !== '' ||
                  passwordError !== '' ||
                  codeError !== '' ||
                  email === '' ||
                  password === '' ||
                  code === ''
                }
              />
            </View>
          </View>
          {emailError !== '' || passwordError !== '' || codeError !== '' ? (
            <Text style={styles.textInputError} accessibilityRole="text">
              Please correct the errors above
            </Text>
          ) : null}
          {loading ? <ActivityIndicator size="small" /> : null}
        </View>
        <View style={styles.backgroundShape} />
      </SafeArea>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20,
  },
  baseText: {
    // fontFamily: 'Lato_Regular',
    paddingBottom: 6,
  },
  titleText: {
    fontSize: 30,
    fontWeight: 'bold',
    // fontFamily: 'Lato_Bold',
    paddingTop: 10,
    paddingBottom: 20,
  },
  linkButton: {
    fontSize: 16,
    fontWeight: 'bold',
    // fontFamily: 'Lato_Regular',
    // paddingLeft: 10,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: '#000000',
  },
  textInput: {
    // fontFamily: 'Lato_Bold',
    height: 42,
    width: '100%',
    borderColor: '#cccccc',
    backgroundColor: '#ffffff',
    borderWidth: 1,
    color: '#000000',
    padding: 5,
  },
  textInputLabel: {
    // fontFamily: 'Lato_Regular',
    marginTop: 15,
    marginBottom: 6,
    color: '#474747',
  },
  textInputError: {
    // fontFamily: 'Lato_Bold',
    marginTop: 6,
    marginBottom: 15,
    marginLeft: 5,
    color: '#CC0000',
  },
  errorBox: {
    width: '100%',
    backgroundColor: '#990000',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 10,
    margin: 5,
  },
  errorText: {
    fontSize: 16,
    color: '#ffffff',
  },
  backgroundShape: {
    width: '100%',
    height: 270,
    zIndex: -1,
    position: 'absolute',
    backgroundColor: color.midBlue,
    borderTopLeftRadius: 300,
    borderTopRightRadius: 300,
    left: 0,
    transform: [
      {
        scaleX: 1.7,
      },
    ],
    bottom: -20,
  },
  buttonContainer: {
    flex: 1,
    zIndex: 2,
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 40,
  },
  signupButton: {
    alignSelf: 'stretch',
    textAlign: 'center',
    marginBottom: 20,
  },
});

export default ForgotPasswordSubmit;
