import React from 'react';
import { withAuth } from '../../authentication';
import SubmitApplication from '../../components/pages/submit-application';
import { observer, inject } from 'mobx-react';
import { Linking } from 'expo';
import { isWeb } from '../../../environment';
import * as WebBrowser from 'expo-web-browser';
import PropTypes from 'prop-types';

class SubmitApplicationPage extends React.Component {
  static propTypes = {
    navigation: PropTypes.object,
    auth: PropTypes.object,
    route: PropTypes.object,
    JobStore: PropTypes.object,
  };
  onClosePress = () => {
    this.props.navigation.goBack();
  };
  onSubmitPress = (application) => {
    const {
      JobStore,
      auth,
      route: {
        params: { id, externalUrl },
      },
    } = this.props;
    if (!application) {
      JobStore.putAppliedJobs(auth, id);
    }
    if (isWeb) {
      WebBrowser.openBrowserAsync(externalUrl);
    } else {
      Linking.openURL(externalUrl);
    }
  };
  render() {
    const {
      route: {
        params: { title, company, description, application },
      },
    } = this.props;
    return (
      <SubmitApplication
        onClose={this.onClosePress}
        onSubmitPress={this.onSubmitPress}
        title={title}
        company={company}
        description={description}
        application={application}
      />
    );
  }
}

export { SubmitApplicationPage };
export default inject('JobStore')(observer(withAuth(SubmitApplicationPage)));
