import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import moment from 'moment';
import { color } from '../../helper/theme-helper';

const ShiftCard = (props) => {
  const { container, detailView, headerTextStyle, rowView } = styles;
  const { company = '', location = '', date = '', title = '', onPress } = props;

  return (
    <TouchableOpacity style={container} onPress={onPress}>
      <View style={[detailView]}>
        <View style={rowView}>
          <Text style={headerTextStyle}>{company}</Text>
          <Text
            style={{
              fontSize: 14,
              color: color.gray,
            }}
          >
            {location}
          </Text>
        </View>
        <View style={rowView}>
          <Text
            style={{
              fontWeight: 'bold',
              fontSize: 14,
              color: color.gray,
            }}
          >
            {date && moment(date).format('MMMM D, YYYY')}
          </Text>
          <Text
            style={{
              fontWeight: 'bold',
              fontSize: 14,
              color: color.darkGray,
            }}
          >
            {title}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  rowView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  detailView: {
    width: '100%',
    backgroundColor: color.white,
    padding: 20,
    borderRadius: 10,
    borderColor: color.backgroundGray,
    borderWidth: 1,
  },
  headerTextStyle: {
    fontSize: 18,
    lineHeight: 22,
    fontWeight: 'bold',
    marginBottom: 10,
    color: color.darkGray,
  },
});

ShiftCard.propTypes = {
  company: PropTypes.string,
  location: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  onPress: PropTypes.func,
};
export { ShiftCard };
