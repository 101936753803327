import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, FlatList, Text } from 'react-native';
import { ShiftCardInfo } from '../../layouts'; //AppButton
import { color } from '../../../helper/theme-helper';
import { observer, inject, Observer } from 'mobx-react';

class Job extends React.Component {
  static propTypes = {
    onFindJobsClick: PropTypes.func.isRequired,
    onJobClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      jobTitle: '',
      location: '',
    };
  }

  renderItem = ({ item }) => {
    return (
      <Observer>
        {() => {
          return (
            <ShiftCardInfo
              date={item.createdAt}
              title={item.title}
              location={item.locations[0]}
              company={item.company}
              jobType={item.jobType}
              isNew={item.lastAppliedAt === null && item.lastViewedAt === null}
              isApplied={item.lastAppliedAt !== null}
              isViewed={item.lastAppliedAt === null && item.lastViewedAt !== null}
              onPress={() => {
                this.props.onJobClick(
                  item.id,
                  item.title,
                  item.company,
                  item.description,
                  item.lastViewedAt,
                  item.externalUrl,
                  !!item.lastAppliedAt
                );
              }}
            />
          );
        }}
      </Observer>
    );
  };
  render() {
    return (
      <View style={styles.container}>
        <Text
          style={{
            fontSize: 14,
            color: color.gray,
            fontWeight: 'bold',
            marginBottom: 10,
            marginTop: 20,
          }}
        >
          Recommended Jobs
        </Text>
        <FlatList
          // TODO: does this only update in real time if we slice the prop?
          // eslint-disable-next-line
          data={this.props.JobStore.jobsData.model.slice()}
          renderItem={this.renderItem}
          ItemSeparatorComponent={() => {
            return <View style={{ height: 5 }} />;
          }}
          keyExtractor={(item, index) => {
            return index.toString();
          }}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingLeft: 17,
    paddingRight: 17,
    justifyContent: 'center',
    backgroundColor: color.white,
  },
});

export default inject('JobStore')(observer(Job));
