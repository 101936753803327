import React, { Component } from 'react';
import { Authentication } from './authentication';

const authentication = new Authentication({});

const withAuth = (WrappedComponent) =>
  // eslint-disable-next-line react/display-name
  class extends Component {
    render() {
      return <WrappedComponent auth={authentication} {...this.props} />;
    }
  };

export { withAuth };
