import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
// Mobile navigation:
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import HomePage from '../containers/home-page';
import JobPage from '../containers/job-page';
import SubmitApplication from '../containers/submit-application';
import { MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Image } from 'react-native';
import { UIHelpLogo } from '../components/assets';
import ProfileDetailPage from '../containers/profile-detail-page';
import ProfilePage from '../containers/profile-page';
import FeedbackPage from '../containers/feedback-page';
import NewQuestionPage from '../containers/new-question-page';
import { color } from '../helper/theme-helper';
import PropTypes from 'prop-types';
import FaqDetailPage from '../containers/faq-detail-page';

const customHeaderOption = ({ navigation }) => {
  return {
    headerBackTitle: 'Back',
    headerShown: true,
    // eslint-disable-next-line react/display-name
    headerTitle: () => (
      <Image source={UIHelpLogo} style={{ height: 32, width: 85, resizeMode: 'contain' }} resizeMode={'contain'} />
    ),
    // eslint-disable-next-line react/display-name
    headerRight: () => (
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('Profile');
        }}
      >
        <MaterialCommunityIcons name="account" color={color.midBlue} size={20} />
      </TouchableOpacity>
    ),
    headerRightContainerStyle: { marginRight: 10 },
  };
};

customHeaderOption.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
};

const Stack = createStackNavigator();

class stackNav extends React.Component {
  render() {
    return (
      <Stack.Navigator
        screenOptions={(props) => {
          return {
            headerTitleAlign: 'center',
            headerShown: true,
            ...customHeaderOption(props),
          };
        }}
        initialRouteName="HomePage"
      >
        <Stack.Screen
          name="HomePage"
          component={HomePage}
          options={{
            title: 'Home',
          }}
        />
        <Stack.Screen
          name="SubmitApplication"
          component={SubmitApplication}
          options={{
            headerShown: false,
          }}
        />
      </Stack.Navigator>
    );
  }
}

class FeedbackNav extends React.Component {
  render() {
    return (
      <Stack.Navigator
        screenOptions={(props) => {
          return {
            headerTitleAlign: 'center',
            headerShown: true,
            ...customHeaderOption(props),
          };
        }}
      >
        <Stack.Screen name="FeedbackPage" component={FeedbackPage} />
        <Stack.Screen name="NewQuestionPage" component={NewQuestionPage} />
        <Stack.Screen name="FaqDetailPage" component={FaqDetailPage} />
      </Stack.Navigator>
    );
  }
}

class jobStackNav extends React.Component {
  render() {
    return (
      <Stack.Navigator
        initialRouteName="JobPage"
        screenOptions={(props) => {
          return {
            headerTitleAlign: 'center',
            headerShown: true,
            ...customHeaderOption(props),
          };
        }}
      >
        <Stack.Screen name="JobPage" component={JobPage} />
        <Stack.Screen
          name="SubmitApplication"
          component={SubmitApplication}
          options={{
            headerShown: false,
          }}
        />
      </Stack.Navigator>
    );
  }
}

const Tab = createBottomTabNavigator();
class TabNav extends React.Component {
  render() {
    return (
      <Tab.Navigator
        initialRouteName="HomePage"
        tabBarOptions={{
          activeTintColor: color.midBlue,
          showLabel: false,
          keyboardHidesTabBar: true,
        }}
      >
        <Tab.Screen
          name="WelcomePage"
          component={stackNav}
          options={{
            tabBarIcon: ({ focused, color, size }) =>
              !focused ? (
                <View style={styles.unfocusedTab}>
                  <MaterialCommunityIcons name="home" color={color} size={size} />
                </View>
              ) : (
                <View style={styles.focusTab}>
                  <MaterialCommunityIcons name="home" color={'#fff'} size={size} />
                </View>
              ),
          }}
        />
        <Tab.Screen
          name="JobPage"
          component={jobStackNav}
          options={{
            tabBarIcon: ({ focused, color, size }) =>
              !focused ? (
                <View style={styles.unfocusedTab}>
                  <MaterialCommunityIcons name="briefcase" color={color} size={size} />
                </View>
              ) : (
                <View style={styles.focusTab}>
                  <MaterialCommunityIcons name="briefcase" color={'#fff'} size={size} />
                </View>
              ),
          }}
        />
        <Tab.Screen
          name="FeedBackPage"
          component={FeedbackNav}
          options={{
            tabBarIcon: ({ focused, color, size }) =>
              !focused ? (
                <View style={styles.unfocusedTab}>
                  <FontAwesome5 name="question-circle" color={color} size={size} />
                </View>
              ) : (
                <View style={styles.focusTab}>
                  <FontAwesome5 name="question-circle" color={'#fff'} size={size} />
                </View>
              ),
          }}
        />
      </Tab.Navigator>
    );
  }
}

class AuthenticatedNavigation extends React.Component {
  render() {
    return (
      <NavigationContainer>
        <Stack.Navigator
          initialRouteName="HomePage"
          screenOptions={(props) => {
            return {
              headerTitleAlign: 'center',
              headerShown: true,
              ...customHeaderOption(props),
            };
          }}
        >
          <Stack.Screen name="Home" component={TabNav} options={{ headerShown: false }} />
          <Stack.Screen name="Profile" component={ProfileDetailPage} />
          <Stack.Screen name="ProfilePage" component={ProfilePage} />
        </Stack.Navigator>
      </NavigationContainer>
    );
  }
}

const styles = StyleSheet.create({
  focusTab: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: color.midBlue,
    justifyContent: 'center',
    alignItems: 'center',
  },
  unfocusedTab: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#F9FBFC',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: color.backgroundGray,
  },
});

export default AuthenticatedNavigation;
