import React from 'react';
import {
  StyleSheet,
  ScrollView,
  View,
  Text,
  Image,
  ImageBackground,
  TouchableOpacity,
} from 'react-native';
import { color, isWeb, normalize } from '../../../helper/theme-helper';
import { observer } from 'mobx-react';
import { AppButton } from '../../layouts';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { UIHelpLogo } from '../../assets';
import PropTypes from 'prop-types';

class SubmitApplication extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    application: PropTypes.bool,
    onSubmitPress: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };
  static defaultProps = {
    application: false,
  };
  render() {
    const {
      container,
      backgroundShape,
      containView,
      innerView,
      headerText,
      detailText,
      descriptionText,
      descriptionDetailText,
      headerLogo,
      fixed,
      line,
    } = styles;
    const { title, description, company, application, onSubmitPress } = this.props;
    return (
      <View style={container}>
        <ImageBackground style={[backgroundShape, fixed, { zIndex: -1 }]} />
        <ScrollView style={{ flex: 1, paddingHorizontal: 16, backgroundColor: 'transparent' }}>
          <View style={containView}>
            <View style={headerLogo}>
              <TouchableOpacity
                onPress={() => {
                  this.props.onClose();
                }}
              >
                <MaterialCommunityIcons name="close" color={color.black} size={20} />
              </TouchableOpacity>
              <Image
                source={UIHelpLogo}
                style={{
                  width: 121,
                  height: 46,
                  resizeMode: 'contain',
                  marginTop: 40,
                  marginBottom: 40,
                  marginLeft: -20,
                  flex: 1,
                }}
                resizeMode={'contain'}
              />
            </View>
            <View style={innerView}>
              <Text style={headerText}>{company}</Text>
              <Text style={detailText}>
                <Text style={{ color: color.midBlue, fontWeight: '500' }}>{title}</Text>
              </Text>
              <View style={line} />
              <Text style={descriptionText}>Description</Text>
              <Text style={descriptionDetailText}>{description}</Text>
            </View>
            <AppButton
              containerStyle={{
                backgroundColor: color.white,
                width: '100%',
                maxWidth: 600,
                height: isWeb ? 63 : 63,
                marginBottom: 40,
                borderRadius: 32,
                marginTop: isWeb ? normalize(40) : 176,
              }}
              onPress={() => onSubmitPress(application)}
              title={application ? 'View More' : 'Apply'}
              titleStyle={{ color: color.midBlue, fontSize: 18 }}
            />
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: color.white,
  },
  backgroundShape: {
    width: '100%',
    height: isWeb ? '60%' : 604.1,
    zIndex: -1,
    backgroundColor: color.midBlue,
    borderTopLeftRadius: 300,
    borderTopRightRadius: 300,
    left: 0,
    transform: [
      {
        scaleX: 1.7,
      },
    ],
    bottom: 0,
  },
  fixed: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
  containView: {
    position: 'absolute',
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 40,
  },
  innerView: {
    width: '100%',
    maxWidth: 600,
    height: isWeb ? '8%' : 350,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.white,
    paddingHorizontal: 20,
    paddingVertical: 28,
    marginTop: isWeb ? normalize(10) : 62,
    borderRadius: 10,
    shadowOpacity: 0.15,
    shadowRadius: 10,
    shadowColor: color.midBlue,
    shadowOffset: { height: 2, width: 0 },
    elevation: 1,
  },
  headerText: {
    fontSize: 22,
    fontWeight: 'bold',
    width: 260,
    height: 28,
    textAlign: 'center',
    lineHeight: 12,
    marginTop: 20,
    color: color.darkGray,
  },
  detailText: {
    fontSize: 14,
    width: 260,
    height: 68,
    textAlign: 'center',
    lineHeight: 12,
    marginTop: 10,
    fontWeight: '300',
  },
  descriptionText: {
    fontSize: 18,
    color: color.darkGray,
    fontWeight: '800',
    width: 260,
    height: 28,
    textAlign: 'center',
    lineHeight: 24,
    marginTop: isWeb ? 25 : 52,
    marginBottom: 10,
  },
  descriptionDetailText: {
    fontSize: 14,
    color: color.darkGray,
    textAlign: 'left',
    lineHeight: 24,
    marginTop: isWeb ? 0 : 21,
  },
  headerLogo: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    marginTop: isWeb ? 0 : 40,
    width: '100%',
  },
  line: {
    height: 1,
    backgroundColor: 'rgba(42, 83, 186, 0.13)',
    width: '100%',
    marginBottom: 25,
  },
});

export default observer(SubmitApplication);
