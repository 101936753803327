export const mask = (userInput) => {
  const numbers = userInput.match(/\d/g);
  let numberLength = 0;
  if (numbers) {
    numberLength = numbers.join('').length;
  }
  if (numberLength === 10) {
    return '(999) 999-9999';
  }
  if (numberLength === 11) {
    return '9 (999) 999-9999';
  }
  if (numberLength === 12) {
    return '(99) 999 999-9999';
  }
  return '(999) 999-9999';
};
