import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DATE_FORMAT } from '../../../helper/strings';

export const CustomDatePicker = (props) => {
  const { disable = false, minDate = '1920-1-1' } = props;
  let minD = moment(minDate, DATE_FORMAT);
  return (
    <DatePicker
      selected={moment(props.date, DATE_FORMAT).toDate()}
      onChange={(date) => props.changeDate(moment(date).format(DATE_FORMAT))}
      minDate={new Date(minD.year(), minD.month(), minD.date())}
      maxDate={new Date()}
      dateFormat={'yyyy-MM-dd'}
      className="container"
      disabled={disable}
      onChangeRaw={(event) => {
        event.preventDefault();
      }}
    />
  );
};

CustomDatePicker.propTypes = {
  date: PropTypes.string,
  minDate: PropTypes.string,
  changeDate: PropTypes.func,
  disable: PropTypes.bool,
};
