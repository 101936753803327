import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, ActivityIndicator, Button, Image, Text, TextInput, View } from 'react-native';
import { SafeArea } from '../../layouts';
import { UIHelpLogo } from '../../assets';
import { color, isWeb } from '../../../helper/theme-helper';

class ConfirmEmail extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.any,
    // By default we're not asking about the email as we already know it.
    // But on web it's possible to reload the email valiadtion page, and then
    // we lose this info. If we don't know the email anymore, then we're
    // asking about it again here, and using that for validation code-resending
    // and validation.
    email: PropTypes.any,
    emailResent: PropTypes.bool.isRequired,
    confirmSignUp: PropTypes.func.isRequired,
    resendEmail: PropTypes.func.isRequired,
  };

  static defaultProps = {
    email: null,
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      code: '',
      codeError: '',
      email: props.email || '',
      emailError: '',
    };
  }

  isEmailValid = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  setEmail = (email) => {
    const emailError = this.isEmailValid(email) ? '' : 'This is not a valid email address';
    this.setState({ email, emailError });
  };

  isCodeValid = (code) => {
    return code.length > 0;
  };

  setCode = (code) => {
    const codeError = this.isCodeValid(code) ? '' : 'Enter the code from your email';
    this.setState({ code, codeError });
  };

  render() {
    const {
      email: emailProp,
      confirmSignUp,
      resendEmail,
      loading,
      error,
      emailResent,
    } = this.props;
    const { email, emailError, code, codeError } = this.state;

    return (
      <SafeArea>
        <View style={styles.container}>
          {error ? (
            <View style={styles.errorBox}>
              <Text style={styles.errorText} accessibilityRole="text">
                Email validation failed
              </Text>
            </View>
          ) : null}
          <View
            style={{
              alignItems: 'center',
              width: '100%',
              marginTop: isWeb ? 0 : 10,
            }}
          >
            <Image
              source={UIHelpLogo}
              style={{
                width: 179,
                height: 68,
                resizeMode: 'contain',
              }}
            />
          </View>
          <View style={{ alignItems: 'center', justifyContent: 'center', marginVertical: 30 }}>
            <Text style={{ fontSize: 22, color: color.midBlue, fontWeight: 'bold' }}>
              Welcome to Syrg
            </Text>
          </View>
          <Text style={styles.baseText} accessibilityRole="text">
            <Text>
              We {emailResent ? 'just re-sent the email' : 'sent an email'} to{' '}
              {emailProp || 'your account'}, it should arrive very soon. Please enter the validation
              code that you find in it.
            </Text>
          </Text>
          <Text
            style={styles.linkButton}
            accessibilityRole="link"
            onPress={() => resendEmail(email)}
            disabled={!!loading}
          >
            Resend the validation email
          </Text>
          {emailProp ? null : (
            <React.Fragment>
              <Text style={styles.textInputLabel} accessibilityRole="text">
                Your email address:
              </Text>
              <TextInput
                onChangeText={this.setEmail}
                value={email}
                placeholder={'Email address'}
                style={styles.textInput}
                autoCapitalize={'none'}
                autoCorrect={false}
                autoFocus={true}
                keyboardType={'email-address'}
                textContentType={'emailAddress'}
                disabled={!!loading}
              />
              <Text style={styles.textInputError} accessibilityRole="text">
                {emailError}
              </Text>
            </React.Fragment>
          )}
          <Text style={styles.textInputLabel} accessibilityRole="text">
            Validation code:
          </Text>
          <TextInput
            onChangeText={this.setCode}
            value={code}
            placeholder={'Validation code'}
            style={styles.textInput}
            autoCapitalize={'none'}
            autoCorrect={false}
            autoFocus={true}
            keyboardType={'numeric'}
            textContentType={'oneTimeCode'}
            disabled={!!loading}
          />
          <Text style={styles.textInputError} accessibilityRole="text">
            {codeError}
          </Text>
          <Button
            title="Next"
            onPress={() => confirmSignUp(email, code)}
            disabled={!!loading || codeError !== '' || code === ''}
          />
          {codeError !== '' ? (
            <Text style={styles.textInputError} accessibilityRole="text">
              Please correct the errors above
            </Text>
          ) : null}
          {loading ? <ActivityIndicator size="small" /> : null}
        </View>
      </SafeArea>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: 20,
  },
  baseText: {
    // fontFamily: 'Lato_Regular',
    paddingBottom: 6,
  },
  titleText: {
    fontSize: 30,
    fontWeight: 'bold',
    fontFamily: 'Lato_Bold',
    paddingTop: 10,
    paddingBottom: 20,
  },
  linkButton: {
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Lato_Regular',
    // paddingLeft: 10,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: '#000000',
  },
  textInput: {
    fontFamily: 'Lato_Bold',
    height: 42,
    width: '100%',
    borderColor: '#cccccc',
    backgroundColor: '#ffffff',
    borderWidth: 1,
    color: '#000000',
    padding: 5,
  },
  textInputLabel: {
    fontFamily: 'Lato_Regular',
    marginTop: 15,
    marginBottom: 6,
    color: '#474747',
  },
  textInputError: {
    fontFamily: 'Lato_Bold',
    marginTop: 6,
    marginBottom: 15,
    marginLeft: 5,
    color: '#CC0000',
  },
  errorBox: {
    width: '100%',
    backgroundColor: '#990000',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 10,
    margin: 5,
  },
  errorText: {
    fontSize: 16,
    color: '#ffffff',
  },
});

export default ConfirmEmail;
