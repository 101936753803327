import { observable, runInAction, decorate } from 'mobx';
import JobService from '../services/job-service';

class JobStore {
  constructor() {
    this.jobService = new JobService();
  }
  jobsData = {
    model: [],
    loading: true,
  };
  // allJobsData = {
  //   model: [],
  // };
  lastAppliedData = {
    model: [],
    loading: true,
  };

  // getSampleJobs = async (auth) => {
  //   const self = this;
  //   try {
  //     const data = await this.jobService.getSample(auth);
  //     runInAction(() => {
  //       this.allJobsData = {
  //         model: data,
  //       };
  //       // TODO: is there a proper mobx way to do this?
  //       // this code requeries every 5 minutes
  //       setTimeout(function () {
  //         self.getSampleJobs(auth);
  //       }, 5 * 60 * 1000);
  //     });
  //   } catch (error) {
  //     runInAction(() => {
  //       this.status = 'error';
  //     });
  //   }
  // };
  getPersonalJobs = async (auth) => {
    const self = this;
    try {
      const data = await this.jobService.getPersonal(auth);
      runInAction(() => {
        this.jobsData = {
          model: data,
          loading: false,
        };
        // TODO: is there a proper mobx way to do this?
        // this code requeries every 5 minutes
        setTimeout(function () {
          self.getPersonalJobs(auth);
        }, 5 * 60 * 1000);
      });
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
  };

  getLastAppliedJobs = async (auth) => {
    try {
      const data = await this.jobService.getLastApplied(auth);
      runInAction(() => {
        this.lastAppliedData = { model: data, loading: false };
      });
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
  };

  putViewedJobs = async (auth, id) => {
    try {
      this.jobService.putViewed(auth, id);
      runInAction(() => {
        const viewedJob = this.jobsData.model.find((q) => q.id === id);
        viewedJob.lastViewedAt = Date.now();
      });
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
  };

  putAppliedJobs = async (auth, id) => {
    try {
      this.jobService.putApplied(auth, id);
      runInAction(() => {
        const appliedJob = this.jobsData.model.find((q) => q.id === id);
        appliedJob.lastAppliedAt = Date.now();
        this.lastAppliedData.model.push({ ...appliedJob });
      });
    } catch (error) {
      runInAction(() => {
        this.status = 'error';
      });
    }
  };
}

decorate(JobStore, {
  jobsData: observable,
  allJobsData: observable,
  lastAppliedData: observable,
  putViewedJobs: observable,
  putAppliedJobs: observable,
});

export default new JobStore();
