import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '../../authentication';
import ProfileDetail from '../../components/pages/profile-detail';
import { observer, inject } from 'mobx-react';

class ProfileDetailPage extends React.Component {
  static propTypes = {
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
    }).isRequired,
    UserStore: PropTypes.object,
    auth: PropTypes.shape({
      signOut: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      education: {},
      employment: {},
    };
  }

  componentDidMount() {
    this.getUser();
    this.getResumeData();
  }

  getUser = async () => {
    const { auth, UserStore } = this.props;
    try {
      await UserStore.getUser(auth);
    } catch (error) {
      console.error(error);
    }
  };

  getResumeData = async () => {
    const { auth, UserStore } = this.props;
    try {
      await UserStore.getResume(auth);
      const data = this.props.UserStore.resumeData.model;
      const education = data.education;
      const employment = data.employment;
      this.setState({ education, employment });
    } catch (error) {
      console.error(error);
    }
  };

  signOut = async () => {
    const { auth, navigation } = this.props;
    await auth.signOut();
    navigation.navigate('WelcomePage');
  };

  newProfile = (key) => {
    const { navigation } = this.props;
    navigation.navigate('ProfilePage', { key });
  };

  editProfile = (data, key) => {
    const { navigation } = this.props;
    navigation.navigate('ProfilePage', { data, key });
  };

  signOutClick = async () => {
    const { auth } = this.props;
    await auth.signOut();
  };

  render() {
    const { education, employment } = this.state;
    return (
      <ProfileDetail
        signOut={this.signOut}
        education={education}
        employment={employment}
        newProfile={this.newProfile}
        editProfile={this.editProfile}
        signOutClick={() => this.signOutClick()}
      />
    );
  }
}

export { ProfileDetailPage };
export default inject('UserStore')(observer(withAuth(ProfileDetailPage)));
