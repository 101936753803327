import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '../../authentication';
import FAQDetail from '../../components/pages/faq-detail';
import { inject, observer } from 'mobx-react';

class FaqDetailPage extends React.Component {
  static propTypes = {
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      setOptions: PropTypes.func.isRequired,
    }).isRequired,
    QuestionsStore: PropTypes.object,
    route: PropTypes.any.isRequired,
    auth: PropTypes.shape({
      signOut: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      questionId: (this.props.route.params && this.props.route.params.id) || '',
      showModal: false,
    };
  }

  componentDidMount() {
    this.getQuestions();
  }

  getQuestions = async () => {
    const { auth, QuestionsStore } = this.props;
    try {
      await QuestionsStore.getQuestion(auth, this.state.questionId);
    } catch (error) {
      console.error(error);
    }
  };
  onSubmit = async (reply) => {
    const { auth, QuestionsStore } = this.props;
    const body = {
      body: JSON.stringify({ message: reply }),
    };
    this.setState({ showModal: true });
    await QuestionsStore.addResponse(auth, this.state.questionId, body);
  };

  onBack = () => {
    this.setState({ showModal: false });
    // this.props.navigation.goBack();
  };

  setHeader = (options) => {
    this.props.navigation.setOptions(options);
  };
  render() {
    const { questionId, showModal } = this.state;
    return (
      <FAQDetail
        questionId={questionId}
        setHeader={this.setHeader}
        showModal={showModal}
        onBack={this.onBack}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export { FaqDetailPage };
export default inject('QuestionsStore')(observer(withAuth(FaqDetailPage)));
