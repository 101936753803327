import React, { useRef, useState, useEffect } from 'react';
import { StyleSheet, View, Text, Picker } from 'react-native';
import { InputText, PhoneInput } from '../../controls';
import { AppButton } from '../../layouts';
import { color, isIOS } from '../../../helper/theme-helper';
import PropTypes from 'prop-types';
import states from '../onboarding/states';

export const ContactInformation = (props) => {
  const { saveClick, profileData } = props;
  const arrLength = 4;
  const inputRefs = [];
  for (let i = 0; i < arrLength; i++) {
    inputRefs[i] = useRef(null);
  }
  const [data, setData] = useState({
    phone: '',
    email: '',
    firstName: '',
    lastName: '',
    address: '',
  });
  useEffect(() => {
    if (profileData) {
      setData({
        phone: profileData.primaryPhone || '',
        email: profileData.email || '',
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        address: profileData.stateCode || '',
      });
    }
  }, []);

  const { container, titleText, btnContainer, btnText } = styles;

  const onChangeText = (key, value) => {
    setData({ ...data, [key]: value });
  };
  const save = () => {
    let phone = data.phone !== '' ? data.phone.match(/\d/g).join('') : '';
    if (phone.length && phone.length < 10) {
      return alert('Invalid phone number');
    }
    if (
      data.email === '' ||
      data.firstName === '' ||
      data.lastName === '' ||
      data.stateCode === ''
    ) {
      return alert('Please enter all fields.');
    }
    const newProfile = {
      ...profileData,
      primaryPhone: phone,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      stateCode: data.address,
      active: true,
    };
    saveClick(newProfile);
  };
  const onSubmitEditing = (nextIndex) => {
    inputRefs[nextIndex].current && inputRefs[nextIndex].current.focus();
  };

  return (
    <View style={container}>
      <Text style={titleText}>Contact Information</Text>
      <PhoneInput
        lable={'Phone number'}
        value={data.phone ?? ''}
        placeholder={'Phone number'}
        onChangeText={(text) => onChangeText('phone', text)}
        inputTextProps={{
          ref: inputRefs[0],
          returnKeyType: 'next',
          keyboardType: 'phone-pad',
          onSubmitEditing: () => {
            onSubmitEditing(1);
          },
        }}
      />
      <InputText
        lable={'Email address'}
        value={data.email ?? ''}
        placeholder={'Email address'}
        onChangeText={(text) => onChangeText('email', text)}
        inputTextProps={{
          ref: inputRefs[1],
          returnKeyType: 'next',
          onSubmitEditing: () => {
            onSubmitEditing(2);
          },
        }}
      />
      <InputText
        lable={'First name'}
        value={data.firstName ?? ''}
        placeholder={'First name'}
        onChangeText={(text) => onChangeText('firstName', text)}
        inputTextProps={{
          ref: inputRefs[2],
          returnKeyType: 'next',
          onSubmitEditing: () => {
            onSubmitEditing(3);
          },
        }}
      />
      <InputText
        lable={'Last name'}
        value={data.lastName ?? ''}
        placeholder={'Last name'}
        onChangeText={(text) => onChangeText('lastName', text)}
        inputTextProps={{
          ref: inputRefs[3],
          returnKeyType: 'done',
        }}
      />
      <Text style={styles.textInputLabel} accessibilityRole="text">
        Select state:
      </Text>
      <Picker
        onValueChange={(x) => onChangeText('address', x)}
        selectedValue={data.address}
        style={[!isIOS ? { height: 42 } : null]}
      >
        <Picker.Item label="" value="" />
        {states.map((v) => (
          <Picker.Item label={v.name} value={v.abbreviation} key={v.abbreviation} />
        ))}
      </Picker>
      <AppButton
        containerStyle={[{ backgroundColor: color.midBlue }, btnContainer]}
        titleStyle={btnText}
        title={'Save'}
        onPress={save}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: color.white,
    paddingHorizontal: 16,
    marginBottom: 25,
  },
  titleText: {
    fontSize: 28,
    fontWeight: 'bold',
    paddingVertical: 30,
    alignSelf: 'center',
    color: color.darkGray,
  },
  btnContainer: {
    marginTop: 15,
    borderRadius: 5,
  },
  btnText: {
    color: color.white,
    fontWeight: 'bold',
  },
  textInputLabel: {
    marginTop: 15,
    fontWeight: 'bold',
    marginBottom: 6,
    color: color.darkGray,
  },
});

ContactInformation.propTypes = {
  profileData: PropTypes.object,
  saveClick: PropTypes.func,
};
