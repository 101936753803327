import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '../../authentication';
import Home from '../../components/pages/home';
import { observer, inject } from 'mobx-react';

class HomePage extends React.Component {
  static propTypes = {
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
    }).isRequired,
    JobStore: PropTypes.shape({
      getPersonalJobs: PropTypes.func.isRequired,
      getLastAppliedJobs: PropTypes.func.isRequired,
      putViewedJobs: PropTypes.func.isRequired,
    }),
    auth: PropTypes.shape({
      signOut: PropTypes.func.isRequired,
      awsFetch: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { auth, JobStore } = this.props;
    JobStore.getPersonalJobs(auth);
    JobStore.getLastAppliedJobs(auth);
  }

  onJobClick = (id, title, company, description, lastViewedAt, externalUrl, application) => {
    const { auth, JobStore } = this.props;
    if (!lastViewedAt) {
      JobStore.putViewedJobs(auth, id);
    }
    this.props.navigation.navigate('SubmitApplication', {
      id,
      title,
      company,
      description,
      externalUrl,
      application,
    });
  };

  onJobSearchClicked = () => {
    this.props.navigation.navigate('JobPage');
  };

  render() {
    return <Home onJobClick={this.onJobClick} onJobSearchClicked={this.onJobSearchClicked} />;
  }
}

export { HomePage };
export default inject('UserStore', 'JobStore')(observer(withAuth(HomePage)));
