class QuestionsService {
  getAllQuestions = async (auth) => {
    const response = await auth.awsFetch('/api/v1/questions', 'GET');
    return response.json();
  };

  getSingleQuestion = async (auth, questionId) => {
    const response = await auth.awsFetch(`/api/v1/questions/${questionId}`, 'GET');
    return response.json();
  };

  postResponse = async (auth, questionId, body) => {
    const response = await auth.awsFetch(`/api/v1/questions/${questionId}/responses`, 'POST', body);
    return response.json();
  };

  postQuestion = async (auth, body) => {
    const response = await auth.awsFetch(`/api/v1/questions`, 'POST', body);
    return response.json();
  };
}

export default QuestionsService;
