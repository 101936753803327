class HubSpot {
  createUser = async (auth, email, firstName, lastName, stateCode) => {
    auth.awsFetch(
      '/create-hs-user',
      'POST',
      {
        body: JSON.stringify({
          email,
          firstName,
          lastName,
          stateCode,
        }),
      },
      true
    );
  };
}

export default new HubSpot();
