import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '../../authentication';
import HubSpot from '../../authentication/hubspot';
import Loading from '../../components/pages/loading';
import Onboarding from '../../components/pages/onboarding';
import { observer, inject } from 'mobx-react';

class OnboardingPage extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    children: PropTypes.node,
    UserStore: PropTypes.shape({
      getUser: PropTypes.func.isRequired,
      registerUser: PropTypes.func.isRequired,
    }).isRequired,
    auth: PropTypes.shape({
      awsFetch: PropTypes.func.isRequired,
      getCurrentUser: PropTypes.func.isRequired,
      signOut: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      processing: false,
      error: false,
      oboardingDone: false,
    };
  }

  componentDidMount() {
    this.getUser();
  }

  getUser = async () => {
    const { auth, UserStore } = this.props;
    try {
      const success = await UserStore.getUser(auth);
      this.setState({ loading: false, oboardingDone: success });
    } catch (error) {
      console.error(`${error}`);
      setTimeout(this.getUser, 5000); // NOTE: trying to get user info every 5 seconds, in case it's a network problem
    }
  };

  registerUser = async (firstName, lastName, stateCode) => {
    if (!firstName || !lastName || !stateCode) {
      return;
    }
    this.setState({ processing: true, error: false });
    const { auth, UserStore } = this.props;
    try {
      const currentUser = await auth.getCurrentUser();
      const { email } = currentUser.attributes;
      const body = {
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          stateCode,
        }),
      };
      const success = await UserStore.registerUser(auth, body);
      this.setState({ oboardingDone: success, error: !success });
      await HubSpot.createUser(auth, email, firstName, lastName, stateCode);
    } catch (error) {
      console.error(`${error}`);
      this.setState({ error: true });
    }
    this.setState({ processing: false });
  };

  signOut = async () => {
    const { auth } = this.props;
    await auth.signOut();
  };

  render() {
    const { children } = this.props;
    const { loading, processing, oboardingDone, error } = this.state;
    if (loading) {
      return <Loading />;
    }
    if (!oboardingDone) {
      return (
        <Onboarding
          save={this.registerUser}
          loading={processing}
          error={error}
          signOut={this.signOut}
        />
      );
    }
    return children;
  }
}

export { OnboardingPage };
export default inject('UserStore')(observer(withAuth(OnboardingPage)));
