import { Auth } from 'aws-amplify';
import aws4 from 'aws4-react-native';
import logger from './logger';
import { host, path, region } from '../../environment';

class Authentication {
  constructor() {
    this.signingUpUser = null;
    this.signingInUser = null;
    this.forgettingPasswordEmail = null;
    this.signedInUser = null;
    this.selectedCorporationId = null;
    this.improgressPassword = null;
  }

  getCurrentUser = async () => {
    this.signedInUser = await Auth.currentAuthenticatedUser();
    return this.signedInUser;
  };

  signUp = async (email, newPassword) => {
    this.improgressPassword = newPassword;
    let error = null;
    try {
      const { user, userConfirmed } = await Auth.signUp({
        username: email,
        password: newPassword,
        // attributes: {
        //    phone_number,   // optional - E.164 number convention
        // }
      });
      if (userConfirmed) {
        logger.info('authentication: signUp: user already confirmed');
        this.signedInUser = user;
        await this.signIn(email, newPassword);
      } else {
        logger.info('authentication: signUp: sending confirmation');
        this.signingUpUser = user;
      }
    } catch (err) {
      this.signingUpUser = null;
      error = err;
      logger.info(`authentication: signUp: error, ${err}`);
      console.info(`authentication: signUp: error, ${err}`);
    }
    return { error };
  };

  getSigningUpUser = () => this.signingUpUser;

  confirmSignUp = async (email, code) => {
    let error = null;
    try {
      const username = email;
      await Auth.confirmSignUp(username, code, {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
        forceAliasCreation: false,
      });
      logger.info('authentication: confirmSignUp: done');
      this.signedInUser = this.signingUpUser;
      this.signingUpUser = null;
      await this.signIn(email, this.improgressPassword);
    } catch (err) {
      logger.info(`authentication: confirmSignUp: failed, ${err}`);
      error = err;
    }
    return { error };
  };

  resendSignUp = async (email) => {
    let error = null;
    try {
      await Auth.resendSignUp(email);
    } catch (err) {
      logger.info(`authentication: confirmSignUp: failed, ${err}`);
      error = err;
    }
    return { error };
  };

  signIn = async (email, password) => {
    let error = null;
    let challengeName = null;
    try {
      this.signingInUser = await Auth.signIn(email, password);
      challengeName = this.signingInUser?.challengeName;
    } catch (err) {
      error = err.message || 'Unexpected error';
      this.signingInUser = null;
    }
    this.improgressPassword = null;
    return { error, challengeName };
  };

  /*
  completeNewPassword = async (newPassword) => {
    let error = null
    const challengeName = null
    try {
      return await Auth.completeNewPassword(
        this.signingInUser,
        newPassword,
        this.signingInUser.challengeParam.requiredAttributes
      )
    } catch (err) {
      error = err.message || 'Unexpected error'
    }

    return { error, challengeName }
  }

  confirmSignIn = async (smsCode, client) => {
    let error = null
    try {
      this.signedInUser = await Auth.confirmSignIn(
        this.signingInUser,
        smsCode,
        'SMS_MFA'
      )
      const { username: cognitoId } = this.signedInUser
      Hub.dispatch(
        'auth',
        { event: 'signIn', data: this.signedInUser },
        'Auth'
      )
    await client
        .query({
          query: getUserByCognitoId,
          fetchPolicy: 'no-cache',
          variables: {
            id: cognitoId
          }
        })
        .then(async user => {
          const userDb =
            user.data &&
            user.data.getUserByCognitoId &&
            user.data.getUserByCognitoId
          await client
            .mutate({
              mutation: updateManager,
              fetchPolicy: 'no-cache',
              variables: {
                id: userDb.id,
                firstName: userDb.firstName,
                lastName: userDb.lastName,
                phoneNumber: userDb.phoneNumber
              }
            })
            .then(() => {
              Hub.dispatch(
                'auth',
                { event: 'signIn', data: this.signedInUser },
                'Auth'
              )
            })
            .catch(() => {})
          this.signingInUser = null
        })
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        error = 'User is not confirmed'
      } else {
        error = err.message || 'Unexpected error'
      }
    }
    return { error }
  }
*/

  forgotPassword = async (email) => {
    let error = null;
    let response = null;
    this.forgettingPasswordEmail = email;
    try {
      response = await Auth.forgotPassword(email);
    } catch (err) {
      error = err;
    }
    return { error, response };
  };

  getForgotPasswordEmail = () => this.forgettingPasswordEmail;

  forgotPasswordSubmit = async (email, code, newPassword) => {
    try {
      const data = await Auth.forgotPasswordSubmit(email, code, newPassword);
      this.forgettingPasswordEmail = null;
      await this.signIn(email, newPassword);
      return { data };
    } catch (error) {
      return { error };
    }
  };

  getLoggedInUserId = async () => {
    const {
      attributes: { sub },
    } = await Auth.currentAuthenticatedUser();
    return sub;
  };

  getCredentials = () => Auth.currentCredentials();

  awsFetch = async (uri, method, options, pathOverride) => {
    const sub = await this.getLoggedInUserId();

    const headers = {
      cache: 'no-cache',
      'content-type': 'application/json',
      ...(options ? options.headers || {} : {}),
      'X-Syrg-User-Id': sub,
    };

    const fetchPath = `${path}${pathOverride ? '' : '/uihelp-org'}${uri}`;

    const signable = {
      host,
      path: fetchPath,
      method: method,
      service: 'execute-api',
      headers,
      region,
    };
    if (method === 'POST' || method === 'PATCH' || method == 'PUT') {
      signable.body = options ? options.body || '{}' : '{}';
    }
    const credentials = await this.getCredentials();
    const request = aws4.sign(signable, credentials);
    return fetch(`https://${host}${fetchPath}`, request);
  };

  signOut = async () => {
    Auth.signOut().catch(() => {});
    // localStorage.clear()
  };
  /*
  globalSignOut = async () => {
    Auth.signOut({ global: true }).catch(() => {})
  }

  changePassword = async (pass, newPass) => {
    const response = null
    const error = null
    return Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, pass, newPass)
      })
      .then(data => {
        return { response: data, error }
      })
      .catch(err => {
        return { response, error: err.message || 'Unexpected error' }
      })
  }
  */
}

export { Authentication };
