import React from 'react';
import { StyleSheet, View, Text, FlatList, Dimensions, TextInput } from 'react-native';
import PropTypes from 'prop-types';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { SafeArea, AppButton } from '../../layouts';
import { isWeb, isANDROID } from '../../../helper/theme-helper';
import { observer, inject, Observer } from 'mobx-react';
const { width } = Dimensions.get('window');
import moment from 'moment';
import { color } from '../../../helper/theme-helper';
import { Linking } from 'expo';
import * as WebBrowser from 'expo-web-browser';

class FAQDetail extends React.Component {
  static propTypes = {
    questionId: PropTypes.number.isRequired,
    setHeader: PropTypes.func,
    onSubmit: PropTypes.func,
    QuestionsStore: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    onBack: PropTypes.func,
  };
  static defaultProps = {
    setHeader: () => {},
    onSubmit: () => {},
    onBack: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      reply: '',
    };
  }

  componentDidMount() {
    this.props.setHeader({
      headerShown: true,
      headerTitle: null,
    });
  }
  onSubmit = () => {
    const { reply } = this.state;
    if (reply.length < 6) {
      alert('Reply is too short. Must be at least 6 characters.');
    } else {
      this.props.onSubmit(reply);
      this.setState({ reply: '' });
    }
  };

  renderUserDetails = (user) => {
    const { authorName = '' } = user || {};
    return (
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={styles.userContainer}>
          <View style={{ marginLeft: 5, marginTop: 10 }}>
            <Text style={styles.fontBold}>{authorName}</Text>
          </View>
        </View>
      </View>
    );
  };

  renderQuestion = () => {
    const { question } = this.props.QuestionsStore.questionWithResponses.model || {};
    return (
      <View style={{ marginHorizontal: 20, marginTop: 10 }}>
        {this.renderUserDetails(question || {})}
        <Text style={{ ...styles.fontRegular, marginBottom: 12, width: width - (375 * 10) / 100 }}>
          {question && question.text}
        </Text>
        <Text style={{ ...styles.fontRegular, marginBottom: 15, color: color.gray }}>{`${
          question && moment(question.time).format('h:mmA  -  MM/DD/YY')
        }`}</Text>
      </View>
    );
  };

  renderHorizontalLine = () => {
    return <View style={styles.line} />;
  };

  onPressLink = (url) => {
    if (isWeb) {
      WebBrowser.openBrowserAsync(url);
    } else {
      Linking.openURL(url);
    }
  };

  formatText = (text) => {
    // const regex = /\[(.*?)\]/;
    return text && text.replace(/\[(.*?)\]/, '');
  };

  formatLink = (text) => {
    const regex = /\[(.*?)\]/;
    const matchArray = text.match(regex);
    if (matchArray && matchArray[1]) {
      return (
        <Text
          style={styles.link}
          accessibilityRole="link"
          onPress={() => this.onPressLink(matchArray[1])}
        >
          {matchArray[1]}
        </Text>
      );
    }
    return <View />;
  };

  renderAnswers = ({ item }) => {
    return (
      <Observer>
        {() => {
          return (
            <View style={styles.answerContainer} key={item && item.id}>
              {this.renderUserDetails(item)}
              <View style={{ marginLeft: 5 }}>
                <Text style={{ width: width - 375 * 25, ...styles.fontRegular }}>
                  {this.formatText(item && item.text)}
                </Text>
                {this.formatLink(item && item.text)}
                <Text
                  style={{
                    ...styles.fontRegular,
                    marginBottom: 15,
                    marginTop: 10,
                    color: color.gray,
                  }}
                >{`${item && moment(item.time).format('h:mmA  -  MM/DD/YY')}`}</Text>
              </View>
            </View>
          );
        }}
      </Observer>
    );
  };

  renderReplyBox = () => {
    const { reply } = this.state;
    return (
      <View style={styles.replyContainer}>
        <View style={styles.inputContainer}>
          <Text style={styles.textInputLabel} accessibilityRole="text">
            Reply
          </Text>
          <TextInput
            onChangeText={(value) => this.setState({ reply: value })}
            value={reply}
            placeholder={'Add Reply…'}
            placeholdercolor={color.white}
            style={styles.textInput}
            autoCapitalize={'none'}
            autoCorrect={false}
            returnKeyType={'done'}
            onSubmitEditing={() => this.onSubmit()}
          />
        </View>
        <AppButton
          title="Submit"
          containerStyle={{ marginLeft: 10, height: 49, width: 80, backgroundColor: color.midBlue, borderRadius: 2, }}
          titleStyle={{ color: color.white, fontSize: 14, }}
          onPress={() => this.onSubmit()}
        />
      </View>
    );
  };
  render() {
    const { answers } = this.props.QuestionsStore.questionWithResponses.model || [];
    // const { modal, modalContent, thankYouText, detailText } = styles;
    return (
      <SafeArea style={{ backgroundColor: color.white }} bottom>
        <KeyboardAwareScrollView
          enableOnAndroid={false}
          keyboardShouldPersistTaps="handled"
          extraScrollHeight={10}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: isWeb ? 10 : isANDROID ? 5 : 0 }}
          style={{ backgroundColor: color.white }}
        >
          <View>
            {this.renderQuestion()}
            {this.renderHorizontalLine()}
            <FlatList
              data={answers ? answers : []}
              renderItem={this.renderAnswers}
              ItemSeparatorComponent={() => this.renderHorizontalLine()}
              keyExtractor={(item) => item.toString()}
              showsVerticalScrollIndicator={false}
            />
            {this.renderHorizontalLine()}
            {this.renderReplyBox()}
          </View>
        </KeyboardAwareScrollView>
      </SafeArea>
    );
  }
}

// {this.props.showModal && (
//   <View style={modal}>
//     <View style={modalContent}>
//       <TouchableOpacity style={{ paddingHorizontal: 15 }}>
//         <Text style={thankYouText}>Thank You!</Text>
//         <Text style={detailText}>
//           Your reply has been recieved. We are reviewing it. It will be posted here soon!
//         </Text>
//         <AppButton title="Got it!" onPress={() => this.props.onBack()} />
//       </TouchableOpacity>
//     </View>
//   </View>
// )}

const styles = StyleSheet.create({
  userContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  link: {
    color: color.midBlue,
  },
  avatarContainer: {
    height: 50,
    width: 50,
    borderRadius: 25,
    overflow: 'hidden',
    left: -5,
  },
  fontBold: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 17,
    color: color.darkGray,
  },
  fontRegular: {
    fontSize: 14,
    lineHeight: 17,
    color: color.darkGray,
  },
  pinnedAnswer: {
    fontSize: 12,
    lineHeight: 15,
    color: '#707682',
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconText: {
    marginLeft: 4,
    marginRight: 20,
    color: '#707682',
  },
  line: {
    height: 1,
    backgroundColor: '#e4ebf7',
    width: '100%',
  },
  answerContainer: {
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 10,
    marginLeft: 30,
  },
  ansBottom: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    marginTop: 10,
  },
  textInput: {
    width: '100%',
    borderColor: '#cccccc',
    backgroundColor: color.white,
    borderWidth: 1,
    color: '#000000',
    padding: 15,
  },
  textInputLabel: {
    marginTop: 15,
    marginBottom: 6,
    color: color.darkGray,
    position: 'absolute',
    zIndex: 10000,
    bottom: 35,
    left: 10,
    paddingHorizontal: 10,
    backgroundColor: color.white,
  },
  replyContainer: {
    marginTop: 40,
    marginHorizontal: 20,
    marginLeft: 40,
    flexDirection: 'row',
    flex: 1,
    backgroundColor: color.white,
  },
  inputContainer: {
    borderWidth: 1,
    borderRadius: 3,
    borderColor: '#90959e',
    flex: 1,
    marginLeft: 7,
  },
  // below is for custom modal,
  // TODO: refactor into it's own component
  thankYouText: {
    fontSize: 18,
    lineHeight: 22,
    fontWeight: 'bold',
    marginBottom: 10,
    color: color.darkGray,
  },
  detailText: {
    marginTop: 10,
    fontSize: 14,
    marginBottom: 20,
  },
  modal: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  modalContent: {
    backgroundColor: '#fefefe',
    marginVertical: '15%',
    padding: 20,
    borderWidth: 1,
    borderColor: color.backgroundGray,
    borderRadius: 5,
    width: '80%',
  },
});

export default inject('QuestionsStore')(observer(FAQDetail));
