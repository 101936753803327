import React from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { color } from '../../../helper/theme-helper';
import { WorkExperience } from './work-experience';
import { Education } from './education';
import { ContactInformation } from './contact-information';
import PropTypes from 'prop-types';
import {
  CONTACT_INFORMATION_KEY,
  EDUCATION_KEY,
  WORK_EXPERIENCE_KEY,
} from '../../../helper/strings';
import { observer, inject } from 'mobx-react';

class Profile extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    screenKey: PropTypes.string,
    saveClick: PropTypes.func,
    deleteClick: PropTypes.func,
    UserStore: PropTypes.object,
  };

  render() {
    const { container } = styles;
    const { screenKey, data, saveClick, deleteClick, UserStore } = this.props;
    const userId = UserStore.userData && UserStore.userData.model.id;
    let renderView = <View style={container} />;
    if (screenKey === WORK_EXPERIENCE_KEY) {
      renderView = (
        <WorkExperience
          userId={userId}
          profileData={data}
          saveClick={(x) => saveClick(x)}
          deleteClick={(x) => deleteClick(x)}
        />
      );
    } else if (screenKey === EDUCATION_KEY) {
      renderView = (
        <Education
          userId={userId}
          profileData={data}
          saveClick={(x) => saveClick(x)}
          deleteClick={(x) => deleteClick(x)}
        />
      );
    } else if (screenKey === CONTACT_INFORMATION_KEY) {
      renderView = <ContactInformation profileData={data} saveClick={(x) => saveClick(x)} />;
    }
    return (
      <View style={container}>
        <ScrollView style={container} showsVerticalScrollIndicator={false}>
          {renderView}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: color.white,
  },
});

export default inject('UserStore')(observer(Profile));
