import React from 'react';
import { StyleSheet, Text, View, ScrollView, TouchableOpacity } from 'react-native';
import { color } from '../../../helper/theme-helper';
import {
  CONTACT_INFORMATION_KEY,
  DATE_FORMAT,
  EDUCATION_KEY,
  WORK_EXPERIENCE_KEY,
} from '../../../helper/strings';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import moment from 'moment';

class ProfileDetail extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string.required,
      firstName: PropTypes.string.required,
      lastName: PropTypes.string.required,
      email: PropTypes.string.required,
      stateCode: PropTypes.string.required,
      primaryPhone: PropTypes.string.required,
      active: PropTypes.string.required,
    }),
    newProfile: PropTypes.func,
    UserStore: PropTypes.object,
    editProfile: PropTypes.func,
    education: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    employment: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    signOutClick: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderLabel = (label = '', key = '') => {
    const { labelText, labelContainer, borderBottom } = styles;
    return (
      <View style={[labelContainer, borderBottom]}>
        <Text style={labelText}>{label}</Text>
        {key !== '' && (
          <TouchableOpacity onPress={() => this.props.newProfile(key)}>
            <Text style={{ ...labelText, marginLeft: 5, color: color.midBlue }}>+ New</Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  renderDetailRow = (displayDetail = null, key = '', details = null, index = 0) => {
    const { labelText, labelContainer } = styles;
    if (!displayDetail) {
      return true;
    }
    const { row1 = '', row2 = '', row3 = '' } = displayDetail;
    let data = details;

    return (
      <View style={labelContainer} key={index}>
        <Text style={{ ...labelText, fontSize: 14, flex: 1 }}>
          {row1}
          <Text style={{ color: color.gray }}>
            {row2}
            <Text style={{ fontWeight: 'normal' }}>{row3}</Text>
          </Text>
        </Text>
        <TouchableOpacity onPress={() => this.props.editProfile(data, key)}>
          <MaterialCommunityIcons name={'pencil'} size={25} color={color.midBlue} />
        </TouchableOpacity>
      </View>
    );
  };

  render() {
    const { container, titleText } = styles;
    const { UserStore, signOutClick } = this.props;
    const user = UserStore.userData && UserStore.userData.model;
    const { employment } = UserStore.userData && UserStore.resumeData.model;
    const { education } = UserStore.userData && UserStore.resumeData.model;
    return (
      <View style={container}>
        <ScrollView style={{ flex: 1, paddingHorizontal: 17 }}>
          <Text style={titleText}>
            {user.firstName} {user.lastName}
          </Text>
          {this.renderLabel('Work Experience', WORK_EXPERIENCE_KEY)}
          {employment &&
            employment.length > 0 &&
            employment.map((item, index) => {
              let endD = item.endDate ? item.endDate : moment().format(DATE_FORMAT);
              return this.renderDetailRow(
                {
                  row1: item.employer + '\n',
                  row2: item.position + '\n',
                  row3: item.startDate + ' - ' + endD,
                },
                WORK_EXPERIENCE_KEY,
                item,
                index
              );
            })}
          {this.renderLabel('Education', EDUCATION_KEY)}
          {education &&
            education.length > 0 &&
            education.map((item, index) => {
              return this.renderDetailRow(
                {
                  row1: item.institution + '\n',
                  row2: item.program,
                  row3: '',
                },
                EDUCATION_KEY,
                item,
                index
              );
            })}
          {this.renderLabel('Contact Information')}
          {this.renderDetailRow(
            {
              row3: (user.primaryPhone ?? ' ') + '\n' + user.email + '\n' + user.stateCode,
            },
            CONTACT_INFORMATION_KEY,
            user
          )}
          <Text style={styles.linkButton} accessibilityRole="link" onPress={() => signOutClick()}>
            Sign out
          </Text>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: color.white,
  },
  titleText: {
    fontSize: 28,
    fontWeight: 'bold',
    paddingTop: 30,
    paddingBottom: 54,
    alignSelf: 'center',
    color: color.darkGray,
  },
  labelText: {
    fontSize: 15,
    fontWeight: 'bold',
    color: color.darkGray,
    lineHeight: 17,
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 12,
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: color.backgroundGray,
    paddingTop: 24,
  },
  linkButton: {
    fontSize: 16,
    fontWeight: 'bold',
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: '#000000',
  },
});

export default inject('UserStore')(observer(ProfileDetail));
