import React from 'react';
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { MaterialIcons } from '@expo/vector-icons'; // FontAwesome
import { color } from '../../../helper/theme-helper';
import states from '../onboarding/states';
import PropTypes from 'prop-types';
import { observer, inject, Observer } from 'mobx-react';
import moment from 'moment';
import { AppButton } from '../../layouts';

class Feedback extends React.Component {
  static propTypes = {
    onNewQuestion: PropTypes.func,
    onListQuestion: PropTypes.func,
    QuestionsStore: PropTypes.object,
    loading: PropTypes.bool,
    showModal: PropTypes.bool,
    toggleModal: PropTypes.func,
  };
  onNewQuestion = () => {
    this.props.onNewQuestion();
  };
  renderItem = ({ item }) => {
    const { titleText, detailText, timeText } = styles;
    const { authorName = '', text = '', id, time } = item;
    return (
      <Observer>
        {() => {
          return (
            <TouchableOpacity
              style={{ paddingHorizontal: 17 }}
              onPress={() => this.props.onListQuestion(id)}
            >
              <Text style={titleText}>{authorName}</Text>
              <Text style={detailText}>{text}</Text>
              <Text style={timeText}>{moment(time).format('h:mmA  -  MM/DD/YY')}</Text>
            </TouchableOpacity>
          );
        }}
      </Observer>
    );
  };
  render() {
    const {
      container,
      headerContainer,
      headerText,
      subHeaderText,
      listSeparator,
      newQuestion,
      newQuestionIcon,
      modalContent,
      modal,
      detailText,
      btnContainer,
      btnText,
    } = styles;
    const {
      QuestionsStore: { questionData },
    } = this.props;
    const questions = questionData && questionData.model.questions;
    const filter = questionData && questionData.model.appliedFilters;
    const state = states.find((v) => v.abbreviation === (filter && filter.stateCode));
    return (
      <View style={container}>
        <View style={{ marginHorizontal: 17, marginTop: 15 }}>
          <View
            style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Text style={headerText}>
              Expert Answers
            </Text>
            <TouchableOpacity
              style={{ flexDirection: 'row', alignItems: 'center' }}
              onPress={this.onNewQuestion}
            >
              <Text style={newQuestionIcon}>+</Text>
              <Text style={newQuestion}>New Question</Text>
            </TouchableOpacity>
          </View>
          {
            // <TouchableOpacity
            //   style={{ marginLeft: 6, marginTop: 2 }}
            //   onPress={() => this.props.toggleModal(true)}
            // >
            //   <FontAwesome name="question-circle-o" color={color.midBlue} size={18} />
            // </TouchableOpacity>
          }
        </View>
        <View style={{ marginHorizontal: 17, marginBottom: 20 }}>
          <View
            style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Text style={subHeaderText}>
              Answers are replied to by former and current unemployment insurance professionals or
              lawyers
            </Text>
          </View>
          <View>
              <Text style={{ fontSize: 14, color: color.midBlue, marginTop: 20, fontWeight: "bold" }}>
                Get your {state && state.name} unemployment questions answered
              </Text>
          </View>
        </View>
        <View style={listSeparator} />
        {this.props.loading ? <ActivityIndicator size="small" /> : null}
        <FlatList
          style={{ flex: 1 }}
          data={questions}
          renderItem={this.renderItem}
          keyExtractor={(item, index) => index.toString()}
          ItemSeparatorComponent={() => <View style={listSeparator} />}
        />
        {this.props.showModal && (
          <View style={modal}>
            <View style={modalContent}>
              <View style={[headerContainer, { margin: 0 }]}>
                <Text style={headerText}>
                  Get your {state && state.name} unemployment questions answered
                </Text>
                <TouchableOpacity onPress={() => this.props.toggleModal(false)}>
                  <MaterialIcons name="close" color={color.darkGray} size={26} />
                </TouchableOpacity>
              </View>
              <Text style={detailText}>
                Answers are replied to by former and current unemployment insurance professionals or
                lawyers.
              </Text>
              <AppButton
                title="Okay"
                onPress={() => this.props.toggleModal(false)}
                containerStyle={btnContainer}
                titleStyle={btnText}
              />
            </View>
          </View>
        )}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: color.white,
  },
  headerContainer: {
    margin: 17,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerText: {
    fontSize: 24,
    lineHeight: 29,
    color: color.darkGray,
    fontWeight: 'bold',
  },
  subHeaderText: {
    fontSize: 14,
    color: color.gray,
    marginTop: 20,
    marginBottom: 10,
  },
  newQuestion: {
    fontSize: 14,
    lineHeight: 17,
    color: color.midBlue,
    fontWeight: 'bold',
  },
  newQuestionIcon: {
    fontSize: 22,
    lineHeight: 25,
    marginRight: 2,
    marginBottom: 4,
    color: color.midBlue,
    fontWeight: 'bold',
  },
  titleText: {
    fontSize: 14,
    lineHeight: 17,
    color: color.darkGray,
    fontWeight: 'bold',
  },
  detailText: {
    marginTop: 10,
    fontSize: 14,
    lineHeight: 17,
    color: color.darkGray,
  },
  timeText: {
    marginTop: 10,
    fontSize: 14,
    lineHeight: 24,
    color: color.gray,
  },
  listSeparator: {
    marginVertical: 10,
    height: 1,
    backgroundColor: color.backgroundGray,
  },
  modal: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  modalContent: {
    backgroundColor: '#fefefe',
    marginVertical: 100,
    padding: 20,
    borderWidth: 1,
    borderColor: color.backgroundGray,
    borderRadius: 5,
    width: '80%',
  },
  btnContainer: {
    backgroundColor: color.midBlue,
    borderRadius: 5,
    marginTop: 20,
  },
  btnText: {
    color: color.white,
    fontSize: 14,
  },
});

export default inject('QuestionsStore')(observer(Feedback));
