import React, { useRef, useState, useEffect } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { InputText, CustomDatePicker } from '../../controls';
import { AppButton } from '../../layouts';
import { color } from '../../../helper/theme-helper';
import { Ionicons } from '@expo/vector-icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DATE_FORMAT } from '../../../helper/strings';

export const WorkExperience = (props) => {
  const { saveClick, deleteClick, profileData } = props;
  const arrLength = 5;
  const inputRefs = [];
  for (let i = 0; i < arrLength; i++) {
    inputRefs[i] = useRef(null);
  }
  const [data, setData] = useState({
    employer: '',
    startDate: moment().format(DATE_FORMAT),
    endDate: moment().format(DATE_FORMAT),
    position: '',
    // jobResponsibilities: '',
    isCurrent: false,
  });

  useEffect(() => {
    if (profileData) {
      setData({
        employer: profileData.employer || '',
        startDate: moment(profileData.startDate).format(DATE_FORMAT) || '',
        endDate: profileData.endDate
          ? moment(profileData.endDate).format(DATE_FORMAT)
          : moment().format(DATE_FORMAT),
        position: profileData.position || '',
        // jobResponsibilities: profileData.jobResponsibilities || '',
        isCurrent: !profileData.endDate || false,
      });
    }
  }, []);

  const { container, titleText, labelText, checkBox, btnContainer, btnText } = styles;

  const onChangeText = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const onSubmitEditing = (nextIndex) => {
    inputRefs[nextIndex].current && inputRefs[nextIndex].current.focus();
  };

  const save = () => {
    if (!moment(data.startDate).isBefore(data.endDate) && data.startDate !== data.endDate) {
      return alert('Please, verify end date with start date.');
    }
    if (
      data.employer === '' ||
      data.startDate === '' ||
      // data.jobResponsibilities === '' ||
      data.position === ''
    ) {
      return alert('Please enter all fields.');
    }
    const newProfile = {
      ...profileData,
      employer: data.employer,
      startDate: data.startDate,
      endDate: !data.isCurrent ? data.endDate : null,
      // jobResponsibilities: data.jobResponsibilities,
      position: data.position,
      currentlyEnrolled: data.isCurrent,
    };
    saveClick(newProfile);
  };

  const deleteEmp = () => {
    deleteClick(profileData.id);
  };
  const renderCheckBox = () => {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
        <TouchableOpacity
          style={checkBox}
          activeOpacity={0.6}
          onPress={() => onChangeText('isCurrent', !data.isCurrent)}
        >
          {data.isCurrent && <Ionicons name={'md-checkmark'} size={20} color={color.darkGray} />}
        </TouchableOpacity>
        <Text style={labelText}>I currently work here</Text>
      </View>
    );
  };
  return (
    <View style={container}>
      <Text style={titleText}>Work Experience</Text>
      <InputText
        lable={'Employer'}
        value={data.employer}
        placeholder={'Employer'}
        onChangeText={(text) => onChangeText('employer', text)}
        inputTextProps={{
          ref: inputRefs[0],
          returnKeyType: 'next',
          onSubmitEditing: () => {
            onSubmitEditing(1);
          },
        }}
      />
      <Text style={styles.textInputLabel} accessibilityRole="text">
        Start Date
      </Text>
      <CustomDatePicker
        date={data.startDate}
        changeDate={(date) => onChangeText('startDate', date)}
      />
      <Text style={styles.textInputLabel} accessibilityRole="text">
        End Date
      </Text>
      <CustomDatePicker
        date={data.endDate}
        changeDate={(date) => onChangeText('endDate', date)}
        disable={data.isCurrent}
        minDate={data.startDate}
      />
      {renderCheckBox()}
      <InputText
        lable={'Position'}
        value={data.position}
        placeholder={'Position'}
        onChangeText={(text) => onChangeText('position', text)}
        inputTextProps={{
          ref: inputRefs[1],
          returnKeyType: 'next',
          onSubmitEditing: () => {
            onSubmitEditing(2);
          },
        }}
      />
      {
        // <InputText
        //   lable={'Job Responsibility'}
        //   value={data.jobResponsibilities}
        //   placeholder={'Job Responsibility'}
        //   onChangeText={(text) => onChangeText('jobResponsibilities', text)}
        //   inputTextProps={{
        //     ref: inputRefs[2],
        //     returnKeyType: 'next',
        //   }}
        // />
      }
      <AppButton
        containerStyle={[{ backgroundColor: color.midBlue }, btnContainer]}
        titleStyle={btnText}
        title={'Save'}
        onPress={save}
      />
      {props.profileData && (
        <AppButton
          containerStyle={[{ backgroundColor: color.red }, btnContainer]}
          titleStyle={btnText}
          title={'Delete'}
          onPress={deleteEmp}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: color.white,
    paddingHorizontal: 16,
    marginBottom: 25,
  },
  titleText: {
    fontSize: 28,
    fontWeight: 'bold',
    paddingVertical: 30,
    alignSelf: 'center',
    color: color.darkGray,
  },
  labelText: {
    fontSize: 15,
    fontWeight: 'bold',
    color: color.darkGray,
    lineHeight: 17,
  },
  textInputLabel: {
    marginTop: 15,
    fontWeight: 'bold',
    marginBottom: 6,
    color: color.darkGray,
  },
  checkBox: {
    height: 20,
    width: 20,
    backgroundColor: color.lightGray,
    borderRadius: 3,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnContainer: {
    marginTop: 15,
    borderRadius: 5,
  },
  btnText: {
    color: color.white,
    fontWeight: 'bold',
  },
  errorText: {
    color: color.red,
    fontWeight: 'bold',
  },
});

WorkExperience.propTypes = {
  profileData: PropTypes.object,
  saveClick: PropTypes.func,
  deleteClick: PropTypes.func,
};
