import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, ActivityIndicator, Text, TextInput, View, Image } from 'react-native';
import { AppButton } from '../../layouts';
import { UIHelpLogo } from '../../assets';
import { color, font, isWeb, hp } from '../../../helper/theme-helper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

class SignIn extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.any,
    signIn: PropTypes.func.isRequired,
    signUp: PropTypes.func.isRequired,
    forgotPasswordClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: '',
      password: '',
      passwordError: '',
    };
  }

  isEmailValid = (email) => {
    return email.length > 0;
  };

  setEmail = (email) => {
    const emailError = this.isEmailValid(email) ? '' : 'Please provide your email address';
    this.setState({ email, emailError });
  };

  isPasswordValid = (password) => {
    return password.length > 0;
  };

  setPassword = (password) => {
    const passwordError = this.isPasswordValid(password) ? '' : 'Please provide your password';
    this.setState({ password, passwordError });
  };

  render() {
    const { signUp, signIn, loading, error, forgotPasswordClick } = this.props;
    const { email, emailError, password, passwordError } = this.state;
    return (
      <View style={{ flex: 1, backgroundColor: color.white }}>
        <KeyboardAwareScrollView
          enableOnAndroid={true}
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
          style={{ flex: 1, backgroundColor: color.midBlue }}
        >
          <View style={styles.container}>
            <View style={styles.backgroundShape} />
            {error ? (
              <View style={styles.errorBox}>
                <Text style={styles.errorText} accessibilityRole="text">
                  Wrong email or password
                </Text>
              </View>
            ) : (
              <View />
            )}
            <View>
              <View
                style={{
                  alignItems: 'center',
                  width: '100%',
                  marginTop: isWeb ? 0 : 10,
                }}
              >
                <Image
                  source={UIHelpLogo}
                  style={{
                    width: 150,
                    height: 57,
                    resizeMode: 'contain',
                    paddingLeft: 4,
                    marginTop: 40
                  }}
                />
              </View>
              <View style={{ alignItems: 'center', justifyContent: 'center', marginVertical: 30 }}>
                <Text style={{ fontSize: 18, color: color.midBlue, fontWeight: 'bold' }}>
                  Welcome to SYRG
                </Text>
              </View>
              <Text style={styles.textInputLabel} accessibilityRole="text">
                Email Address:
              </Text>
              <TextInput
                onChangeText={this.setEmail}
                value={email}
                placeholder={'Email address'}
                style={styles.textInput}
                autoCapitalize={'none'}
                autoCorrect={false}
                autoFocus={true}
                keyboardType={'email-address'}
                textContentType={'emailAddress'}
                disabled={!!loading}
              />
              <Text style={styles.textInputError} accessibilityRole="text">
                {emailError}
              </Text>
              <Text style={styles.textInputLabel} accessibilityRole="text">
                Your password:
              </Text>
              <TextInput
                onChangeText={this.setPassword}
                value={password}
                placeholder={'Your password'}
                style={styles.textInput}
                autoCapitalize={'none'}
                autoCorrect={false}
                autoFocus={false}
                keyboardType={'default'}
                textContentType={'password'}
                secureTextEntry={true}
                disabled={!!loading}
              />
              <Text style={styles.textInputError} accessibilityRole="text">
                {passwordError}
              </Text>
            </View>
            <View style={styles.buttonContainer}>
              {emailError !== '' || passwordError !== '' ? (
                <Text style={styles.textInputError} accessibilityRole="text">
                  Please correct the errors above
                </Text>
              ) : null}
              <View style={styles.signupButton}>
                <AppButton
                  containerStyle={{
                    backgroundColor: color.white,
                    width: '100%',
                    height: isWeb ? 50 : 63,
                    borderRadius: 32,
                  }}
                  title="Log in"
                  onPress={() => signIn(email, password)}
                  disabled={
                    !!loading ||
                    emailError !== '' ||
                    passwordError !== '' ||
                    (email === '' && password === '')
                  }
                />
              </View>

              {loading ? <ActivityIndicator size="small" /> : <View />}
              <Text
                style={styles.linkButton}
                accessibilityRole="link"
                onPress={() => signUp()}
                disabled={!!loading}
              >
                Not signed up yet?
              </Text>
              <Text
                style={styles.linkButton}
                accessibilityRole="link"
                onPress={() => forgotPasswordClick()}
              >
                Forgot your password?
              </Text>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: hp(2),
    paddingHorizontal: 20,
    backgroundColor: color.white,
  },
  backgroundShape: {
    width: '100%',
    height: isWeb ? '35%' : hp(30),
    zIndex: -1,
    position: 'absolute',
    backgroundColor: color.midBlue,
    borderTopLeftRadius: 300,
    borderTopRightRadius: 300,
    left: 0,
    transform: [
      {
        scaleX: 1.7,
      },
    ],
    bottom: 0,
    right: 0,
  },
  baseText: {
    fontFamily: font.Lato_Regular,
    paddingBottom: 6,
  },
  titleText: {
    fontSize: 30,
    fontWeight: 'bold',
    fontFamily: font.Lato_Bold,
    paddingTop: 10,
    paddingBottom: 20,
  },
  linkButton: {
    fontSize: 14,
    fontFamily: 'Lato_Regular',
    paddingLeft: 10,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    textAlign: 'center',
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    color: color.white,
  },
  textInput: {
    fontFamily: font.Lato_Bold,
    height: 42,
    width: '100%',
    borderColor: '#cccccc',
    backgroundColor: color.white,
    borderWidth: 1,
    color: '#000000',
    padding: 5,
  },
  textInputLabel: {
    fontFamily: font.Lato_Regular,
    marginTop: isWeb ? 4 : 15,
    marginBottom: 5,
    color: '#474747',
  },
  textInputError: {
    fontFamily: font.Lato_Bold,
    marginTop: 6,
    marginBottom: 15,
    marginLeft: 5,
    color: '#CC0000',
  },
  errorBox: {
    width: '100%',
    backgroundColor: '#990000',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 10,
    margin: 5,
  },
  errorText: {
    fontSize: 16,
    color: color.white,
  },
  signupButton: {
    alignSelf: 'stretch',
    textAlign: 'center',
    marginBottom: 20,
  },
  buttonContainer: {
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: isWeb ? hp(20) : hp(12),
  },
  upperFlex: {
    flex: 1,
    justifyContent: 'flex-start',
  },
});

export default SignIn;
