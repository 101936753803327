import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '../../authentication';
import Profile from '../../components/pages/profile';
import { observer, inject } from 'mobx-react';
import { CONTACT_INFORMATION_KEY, EDUCATION_KEY, WORK_EXPERIENCE_KEY } from '../../helper/strings';

class ProfilePage extends React.Component {
  static propTypes = {
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      goBack: PropTypes.func,
    }).isRequired,
    route: PropTypes.object,
    auth: PropTypes.shape({}),
    UserStore: PropTypes.shape({
      updateUser: PropTypes.func,
      addEmployment: PropTypes.func,
      updateEmployment: PropTypes.func,
      deleteEmployment: PropTypes.func,
      addEducation: PropTypes.func,
      updateEducation: PropTypes.func,
      deleteEducation: PropTypes.func,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      navigationData: (this.props.route.params && this.props.route.params.data) || null,
      navigationKey: (this.props.route.params && this.props.route.params.key) || '',
    };
  }

  saveClick = (data) => {
    const { navigationKey, navigationData } = this.state;
    const { auth, UserStore, navigation } = this.props;
    const body = {
      body: JSON.stringify(data),
    };
    if (navigationKey === WORK_EXPERIENCE_KEY) {
      if (navigationData) {
        UserStore.updateEmployment(auth, data.id, body);
      } else {
        UserStore.addEmployment(auth, body);
      }
    } else if (navigationKey === EDUCATION_KEY) {
      if (navigationData) {
        UserStore.updateEducation(auth, data.id, body);
      } else {
        UserStore.addEducation(auth, body);
      }
    } else if (navigationKey === CONTACT_INFORMATION_KEY) {
      UserStore.updateUser(auth, body);
    }
    navigation.goBack();
  };

  deleteClick = (id) => {
    const { navigationKey } = this.state;
    const { auth, UserStore, navigation } = this.props;
    if (navigationKey === WORK_EXPERIENCE_KEY) {
      UserStore.deleteEmployment(auth, id);
    } else if (navigationKey === EDUCATION_KEY) {
      UserStore.deleteEducation(auth, id);
    }

    navigation.goBack();
  };

  render() {
    return (
      <Profile
        data={this.state.navigationData}
        screenKey={this.state.navigationKey}
        saveClick={(x) => this.saveClick(x)}
        deleteClick={(x) => this.deleteClick(x)}
      />
    );
  }
}

export { ProfilePage };
export default inject('UserStore')(observer(withAuth(ProfilePage)));
