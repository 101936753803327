import React from 'react';
import PropTypes from 'prop-types';
import Welcome from '../../components/pages/welcome/welcome';

export default class WelcomePage extends React.Component {
  static propTypes = {
    navigation: PropTypes.any,
  };

  signUp = () => {
    const { navigation } = this.props;
    navigation.navigate('SignUpPage');
  };

  logIn = () => {
    const { navigation } = this.props;
    navigation.navigate('LogInPage');
  };

  render() {
    return <Welcome signUp={this.signUp} logIn={this.logIn} />;
  }
}
