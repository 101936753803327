import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Swiper from './swiper';
import Welcome1 from './welcome1';
import Welcome2 from './welcome2';
import Welcome3 from './welcome3';

export default class Welcome extends Component {
  static propTypes = {
    navigation: PropTypes.object.isRequired,
    signUp: PropTypes.func.isRequired,
    logIn: PropTypes.func.isRequired,
  };
  render() {
    return (
      <Swiper navigation={this.props.navigation}>
        <Welcome1 signUp={this.props.signUp} logIn={this.props.logIn} />
        <Welcome2 signUp={this.props.signUp} logIn={this.props.logIn} />
        <Welcome3 signUp={this.props.signUp} logIn={this.props.logIn} />
      </Swiper>
    );
  }
}
