import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '../../authentication';
import ForgotPasswordSubmit from '../../components/pages/forgot-password-submit';

class ForgotPasswordSubmitPage extends React.Component {
  static propTypes = {
    auth: PropTypes.shape({
      forgotPasswordSubmit: PropTypes.func.isRequired,
      resendSignUp: PropTypes.func.isRequired,
      getForgotPasswordEmail: PropTypes.func,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      emailResent: false,
    };
  }

  forgotPasswordSubmitClick = async (email, code, newPassword) => {
    this.setState({ loading: true });
    const { auth } = this.props;
    const { error } = await auth.forgotPasswordSubmit(email, code, newPassword);
    if (error) {
      this.setState({ loading: false, error });
    } else {
      this.setState({ loading: false, error: null });
    }
  };

  resendEmailClick = async (email) => {
    this.setState({ loading: true });
    const { auth } = this.props;
    const { error } = await auth.resendSignUp(email);
    if (error) {
      this.setState({ loading: false, error });
    } else {
      this.setState({ loading: false, error: null, emailResent: true });
    }
  };

  render() {
    const { loading, error, emailResent } = this.state;
    const { auth } = this.props;
    const email = auth.getForgotPasswordEmail() || null;
    return (
      <ForgotPasswordSubmit
        loading={loading}
        error={error}
        email={email}
        emailResent={emailResent}
        forgotPasswordSubmitClick={this.forgotPasswordSubmitClick}
        resendEmailClick={this.resendEmailClick}
      />
    );
  }
}

export { ForgotPasswordSubmitPage };
export default withAuth(ForgotPasswordSubmitPage);
