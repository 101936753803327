import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import { color } from '../../helper/theme-helper';
import moment from 'moment';

const ShiftCardInfo = (props) => {
  const { detailView, headerTextStyle, innerText, rightText, rowView } = styles;
  const {
    title = '',
    location = '',
    company = '',
    date = '',
    jobType = '',
    isNew,
    isApplied,
    isViewed,
    onPress,
  } = props;
  const days = date && moment().diff(date, 'days');

  let rightData = null;
  if (isNew || isApplied) {
    rightData = <Text style={rightText}>{isNew ? 'New' : 'Applied'}</Text>;
  } else if (isViewed) {
    rightData = <Text style={[rightText, { color: color.gray }]}>Viewed</Text>;
  }

  return (
    <TouchableOpacity style={detailView} onPress={onPress}>
      <View style={rowView}>
        <Text style={headerTextStyle}>
          {title} - {jobType}
        </Text>
        {rightData}
      </View>
      <Text style={innerText}>{company}</Text>
      <Text style={[innerText, { marginTop: 0 }]}>
        {location == 'All' ? 'Nationwide' : location}
      </Text>
      <Text style={[innerText, { fontSize: 12 }]}>
        {days === 0 ? 'Today' : days === 1 ? 'Yesterday' : `${days} Days Ago`}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  rowView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  detailView: {
    backgroundColor: color.white,
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 10,
    borderColor: color.backgroundGray,
    borderWidth: 1,
  },
  headerTextStyle: {
    fontSize: 18,
    lineHeight: 22,
    fontWeight: 'bold',
  },
  innerText: {
    fontSize: 14,
    color: color.gray,
    marginTop: 14,
    lineHeight: 18,
  },
  rightText: {
    fontSize: 14,
    marginTop: 2,
    color: color.midBlue,
    fontWeight: 'bold',
  },
});

ShiftCardInfo.propTypes = {
  title: PropTypes.string,
  location: PropTypes.string,
  company: PropTypes.string,
  date: PropTypes.string,
  jobType: PropTypes.string,
  link: PropTypes.string,
  isNew: PropTypes.bool,
  isApplied: PropTypes.bool,
  isViewed: PropTypes.bool,
  onPress: PropTypes.func,
};
export { ShiftCardInfo };
