import React from 'react';
import PropTypes from 'prop-types';
import NewQuestion from '../../components/pages/new-question';
import { inject, observer } from 'mobx-react';
import { withAuth } from '../../authentication';

class NewQuestionPage extends React.Component {
  static propTypes = {
    navigation: PropTypes.any,
    QuestionsStore: PropTypes.object,
    auth: PropTypes.shape({
      signOut: PropTypes.func.isRequired,
    }).isRequired,
  };
  setHeader = (options) => {
    this.props.navigation.setOptions(options);
  };
  constructor(props) {
    super(props);
  }
  onPost = async (message) => {
    const { auth, QuestionsStore } = this.props;
    const body = {
      body: JSON.stringify({ message: message }),
    };
    // this.setState({ showModal: true });
    await QuestionsStore.addQuestion(auth, body);
    this.props.navigation.goBack();
  };
  render() {
    return <NewQuestion setHeader={this.setHeader} onPost={this.onPost} />;
  }
}
export { NewQuestionPage };
export default inject('QuestionsStore')(observer(withAuth(NewQuestionPage)));
